/**
 * UserAccountContext
 *
 * This context is responsible for
 * fetching and passing down the account data
 * to children components and
 * updating user account data on events
 *
 */
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import UserOperation from "../api/UserOperation";
import { Text } from "@aws-amplify/ui-react";
import { UserInterface, ErrorInterface } from "../interface";
import { DataEvents, EventsName } from "../events/index";
import { OAuthProfileType } from "./AuthorizationContext";
import { Onboarding } from "../components";

const UserAccountContext = createContext<UserInterface | null>(null);

export const useUserAccount = (): UserInterface => {
  const context = useContext(UserAccountContext);

  if (!context) {
    throw new Error(
      "useUserAccount must be used within a UserAccountContextProvider",
    );
  }

  return context;
};

export const UserAccountContextProvider = ({
  OAuthProfile,
  children,
}: {
  OAuthProfile: OAuthProfileType;
  children: React.ReactNode;
}) => {
  const [userAccount, setUserAccount] = useState<UserInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateError, setUpdateError] = useState<ErrorInterface | null>(null);
  const contextValues = useMemo(() => userAccount, [userAccount]);
  const { lastName, firstName, email } = OAuthProfile;

  useEffect(() => {
    const fetchUserAccount = async (isRefresh?: boolean) => {
      setIsLoading(true);
      try {
        const userOperation = new UserOperation();
        const user = await userOperation.findUserByEmail(email);
        if (user.id) {
          setUserAccount(user);
        }
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
      if (isRefresh) {
        new DataEvents(EventsName.COMPLETE_REFRESH).postMessage(
          "complete refresh",
        );
      }
    };

    fetchUserAccount();

    const userAccountContextChannel = new DataEvents(
      EventsName.REFRESH_USER_CONTENT,
      () => {
        try {
          fetchUserAccount(true);
        } catch (error) {
          setUpdateError(error as ErrorInterface);
        }
      },
    );

    return () => {
      userAccountContextChannel.close();
    };
  }, [email]);

  if (updateError) {
    console.error(updateError);
    return <Text>{`Something went wrong: ${updateError}`}</Text>;
  }

  if (isLoading) {
    return <Text>...Loading</Text>;
  }

  return userAccount ? (
    <UserAccountContext.Provider value={contextValues}>
      {children}
    </UserAccountContext.Provider>
  ) : (
    <Onboarding firstName={firstName} lastName={lastName} email={email} />
  );
};
