import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {
  AuthorizationContextProvider
} from "./context";
import { BrowserRouter as Router } from "react-router-dom";
import { lynxTheme } from "./styles/themeConfig";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { WebTheme } from "@aws-amplify/ui";

import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <AmplifyProvider theme={lynxTheme as unknown as WebTheme}>
        <AuthorizationContextProvider>
          <App />
        </AuthorizationContextProvider>
      </AmplifyProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
