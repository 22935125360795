import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  createContext,
} from "react";
import { AuthorizationError } from "../interface";
import { handleOAuthError } from "../util/authorizationUtil";
import { Amplify, Hub, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import useURLQuery from "../hooks/useURLQuery";

export type OAuthProfileType = {
  firstName: string;
  lastName: string;
  email: string;
};

type AuthorizationContextType = {
  isAuthenticating: boolean;
  isLoggedIn: boolean;
  OAuthProfile: OAuthProfileType | null;
  error: AuthorizationError | null;
};

const AuthorizationContext = createContext<
  AuthorizationContextType | undefined
>(undefined);

export const useAuthorization = () => {
  const context = useContext(AuthorizationContext);

  if (!context) {
    throw new Error(
      "useAuthorization must be used within a AuthorizationContextProvider",
    );
  }

  return context;
};

export const AuthorizationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // aws auth config
  const urlQuery = useURLQuery();
  const isLocalhost = window.location.hostname === "localhost";
  const [productionRedirectSignIn, localRedirectSignIn] =
    awsconfig.oauth.redirectSignIn.split(",");
  const [productionRedirectSignOut, localRedirectSignOut] =
    awsconfig.oauth.redirectSignOut.split(",");
  const [isLoading, setIsLoading] = useState(true);
  const [authorizationError, setAuthorizationError] =
    useState<AuthorizationError | null>(null);
  const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
      ...awsconfig.oauth,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut,
    },
  };
  // user data
  const [OAuthUser, setOAuthUser] = useState<OAuthProfileType | null>(null);
  const contextValues = useMemo(() => {
    return {
      isAuthenticating: isLoading,
      isLoggedIn: OAuthUser != null,
      OAuthProfile: OAuthUser,
      error: authorizationError,
    };
  }, [isLoading, OAuthUser, authorizationError]);

  Amplify.configure(updatedAwsConfig);

  function getUserDataFromnOAuthProfile(
    authenticatedUser: {
      signInUserSession: {
        idToken: {
          payload: {
            family_name: string;
            given_name: string;
            email: string;
          }
        }
      }
    },
  ): OAuthProfileType {
    const {
      signInUserSession: {
        idToken: {
          payload: { family_name: lastName, given_name: firstName, email },
        },
      },
    } = authenticatedUser;
    
    return {
      firstName,
      lastName,
      email,
    };
  }

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          setIsLoading(true);
          if (!data) {
            throw new Error("Something went Wront: Missing OAuth Profile");
          }
          setOAuthUser(getUserDataFromnOAuthProfile(data));
          setIsLoading(false);
          break;
        case "signOut":
          setOAuthUser(null);
          setIsLoading(false);
          break;
        default:
          return;
      }
    });

    (async () => {
      try {
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        if (!authenticatedUser) {
          throw new Error("Something went Wront: Missing OAuth Profile");
        }
        setOAuthUser(getUserDataFromnOAuthProfile(authenticatedUser));
        setIsLoading(false);
      } catch (error) {
        setAuthorizationError(handleOAuthError(urlQuery));
        setIsLoading(false);
        throw new Error("Failed to authenticate");
      }
    })();

    return unsubscribe;
  }, []);

  return (
    <AuthorizationContext.Provider value={contextValues}>
      {children}
    </AuthorizationContext.Provider>
  );
};
