import Select, { Props, components, OptionProps } from "react-select";
import { useLynxTheme } from "../hooks";

export type PropTypes = Props;

type OptionPropData = {
  icon: JSX.Element | null;
};

export default function TagsInput({ ...otherProps }: Props) {
  const {
    tokens: { space },
  } = useLynxTheme();
  
  return (
    <Select
      theme={(theme) => {
        return {
          ...theme,
          colors: {
            ...theme.colors,
            primary25: `var(--amplify-colors-gray-10)`,
            primary: `var(--amplify-colors-brand-tertiary)`,
          },
        };
      }}
      styles={{
        option: (provided) => ({
          ...provided,
          padding: `${space.xxxs.value} ${space.xs.value}`,
          cursor: "pointer",
          display: "inline-flex",
          alignItems: "center",
          gap: space.xxs.value,
        }),
        control: (_provided, state) => ({
          display: "flex",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: state.isFocused
            ? "var(--amplify-brand-colors-primary)"
            : "var(--amplify-colors-gray-20)",
          backgroundColor: `var(--amplify-colors-white)`,
          borderRadius: `var(--amplify-components-fieldcontrol-border-radius)`,
          padding: `var(--amplify-space-xxxs) 0`,
        }),
        multiValue: () => ({
          display: "flex",
          backgroundColor: `var(--amplify-colors-gray-10)`,
          borderRadius: `var(--amplify-components-badge-border-radius)`,
          padding: `var(--amplify-space-xxxs)`,
          margin: `var(--amplify-space-xxxs)`,
        }),
        multiValueRemove: (styles) => ({
          ...styles,
          borderRadius: "50%",
          padding: `var(--amplify-space-xxxs)`,
          ":hover": {
            backgroundColor: `var(--amplify-colors-gray-20)`,
            cursor: "pointer",
            color: `var(--amplify-colors-white)`,
          },
        }),
      }}
      components={{
        Option: ({ children, data, ...props }: OptionProps) => {
          return (
            <components.Option data={data} {...props}>
              {(data as OptionPropData)?.icon}
              {children}
            </components.Option>
          );
        },
      }}
      {...otherProps}
    />
  );
}
