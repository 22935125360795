import { useEffect, useState } from "react";
import { View } from "@aws-amplify/ui-react";
import { Loader } from "@aws-amplify/ui-react";
import { DataEvents, EventsName } from "../events/index";
import styled from "styled-components";
import { useLynxTheme } from "../hooks";

type PropTypes = {
  delay: number;
};

const StyledView = styled(View)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  transition: all var(--animation-speed-medium) var(--animation-timing-1);
  z-index: var(--amplify-z-index-loading-screen);
`;

const Backdrop = styled(View)`
  background: var(--amplify-colors-gray-10);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.75;
`;

function LoadingScreen({ delay = 1000 }: PropTypes) {
  const [isVisible, setIsVisible] = useState(false);
  const {
    tokens: { colors },
  } = useLynxTheme();

  useEffect(() => {
    const startRefreshChannel = new DataEvents(
      EventsName.START_REFRESH,
      () => {
        setIsVisible(true);
      },
    );
    const completeRefreshChannel = new DataEvents(
      EventsName.COMPLETE_REFRESH,
      () => {
        setIsVisible(false);
      },
    );
    return () => {
      startRefreshChannel.close();
      completeRefreshChannel.close();
    };
  }, [delay]);

  return isVisible ? (
    <StyledView>
      <Loader emptyColor={colors.brand.primary} filledColor={colors.gray[10]} size="large" />
      <Backdrop />
    </StyledView>
  ) : null;
}

export default LoadingScreen;
