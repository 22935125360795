import React, { useState, useEffect } from "react";
import {
  Grid,
  Heading,
  Flex,
  View,
  Badge,
  Button,
  CheckboxField,
  Text,
} from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";
import { ProfileTypesEnum, SkillInterface } from "../interface";
import {
  groupSkillsByType,
  getProfileColorByType,
} from "../util/skillsDataUtil";
import NewAttributeEditor from "./NewAttributeEditor";
import {
  hasExactAttributeMatch,
  PROFILE_TYPES_NAME,
} from "../util/skillsDataUtil";
import SkillOperation from "../api/SkillOperation";
import { LynxSuspense, LynxIcon } from "./atoms";

type PropType = {
  skillsData: Array<SkillInterface>;
  setIsSkillsEdited: (params: Array<SkillInterface> | null) => void;
};

export default function SkillEditor({
  skillsData: currentUserSkills,
  setIsSkillsEdited,
}: PropType) {
  const {
    tokens: { space, colors, fontWeights },
  } = useLynxTheme();
  const [allSkills, setAllSkills] = useState<Array<SkillInterface>>([]);
  const [userSkills, setUserSkills] =
    useState<Array<SkillInterface>>(currentUserSkills);
  const [shouldShowNewAttributeEditor, setShouldShowNewAttributeEditor] =
    useState(false);

  useEffect(() => {
    (async () => {
      setAllSkills(await new SkillOperation().getSkills());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (
        currentUserSkills.length > userSkills.length ||
        currentUserSkills.length < userSkills.length ||
        userSkills.find((skill) => skill.removedFromUser) !== undefined
      ) {
        setIsSkillsEdited(
          userSkills.map(
            ({ id, name, desc, removedFromUser, isNewSkill, types }) => {
              return {
                id,
                name,
                desc,
                removedFromUser,
                isNewSkill,
                types,
              };
            },
          ),
        );
      } else {
        setIsSkillsEdited(null);
      }
    })();
  }, [currentUserSkills.length, setIsSkillsEdited, userSkills]);

  function updateSavedUserSkills(
    savedUserSkills: Array<SkillInterface>,
    selectedUserSkill: SkillInterface,
    isRemoved: boolean,
  ) {
    return savedUserSkills.map((skill) => {
      if (skill.id === selectedUserSkill.id) {
        skill.removedFromUser = isRemoved;
      }
      return skill;
    });
  }

  const updateUserSkills = ({
    event: {
      target: { checked },
    },
    skill: selectedSkill,
  }: {
    event: {
      target: {
        checked: boolean;
      };
    };
    skill: SkillInterface;
  }) => {
    if (checked) {
      setUserSkills((oldUserSkills) => {
        if (!oldUserSkills.find(({ id }) => id === selectedSkill.id)) {
          selectedSkill.isNewSkill = true;
          return [...oldUserSkills, selectedSkill];
        } else {
          return updateSavedUserSkills(oldUserSkills, selectedSkill, false);
        }
      });
    } else {
      setUserSkills((oldUserSkills) =>
        selectedSkill.isNewSkill
          ? oldUserSkills.filter((skill) => skill.id !== selectedSkill.id)
          : updateSavedUserSkills(oldUserSkills, selectedSkill, true),
      );
    }
  };

  const addNewCustomAttribute = (attribute: SkillInterface) => {
    if (
      !hasExactAttributeMatch(attribute, allSkills) &&
      !hasExactAttributeMatch(attribute, userSkills)
    ) {
      setUserSkills((oldUserSkills) => [...oldUserSkills, attribute]);
      setAllSkills((oldAllSkills) => [...oldAllSkills, attribute]);
    }
  };

  const renderSkillCheckbox = () => {
    const groupedAllSkills = groupSkillsByType(allSkills);
    const groupedKeys = Object.keys(groupedAllSkills);
    return groupedAllSkills || groupedKeys.length > 0
      ? groupedKeys.map((objKey, key) => (
          <View key={key}>
            <Flex
              gap={space.xxxs}
              margin={`0 0 ${space.xxxs} 0`}
              alignItems="center"
            >
              <View
                width={space.xxs}
                height={space.xxs}
                borderRadius="50%"
                backgroundColor={`${getProfileColorByType(
                  objKey as ProfileTypesEnum,
                )}`}
              />
              <Heading level={5}>
                {PROFILE_TYPES_NAME[objKey as keyof typeof ProfileTypesEnum]}
              </Heading>
            </Flex>
            {groupedAllSkills[objKey]?.map((skill, groupKey) => (
              <View as="li" key={groupKey}>
                <CheckboxField
                  label={skill.name}
                  name={skill.name}
                  defaultChecked={
                    userSkills.find(({ name, types, removedFromUser }) => {
                      return (
                        name === skill.name &&
                        types === skill.types &&
                        !removedFromUser
                      );
                    }) !== undefined
                  }
                  value={skill.name}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    updateUserSkills({ event, skill })
                  }
                />
              </View>
            ))}
          </View>
        ))
      : null;
  };

  function renderUserSkillCloud() {
    const groupedUserSkills = groupSkillsByType(userSkills);
    return Object.keys(groupedUserSkills).map((objKey, key) => (
      <View key={key}>
        <Flex
          gap={space.xxxs}
          margin={`0 0 ${space.xxxs} 0`}
          alignItems="center"
        >
          <View
            width={space.xxs}
            height={space.xxs}
            borderRadius="50%"
            backgroundColor={`${getProfileColorByType(
              objKey as ProfileTypesEnum,
            )}`}
          />
          <Heading level={5}>
            {PROFILE_TYPES_NAME[objKey as keyof typeof ProfileTypesEnum]}
          </Heading>
        </Flex>
        <Flex gap={space.xxxs} padding={"0"} wrap="wrap">
          {groupedUserSkills[objKey]?.map(
            (skill, skillKey) =>
              !skill.removedFromUser && (
                <Badge
                  backgroundColor={colors.gray[10]}
                  size="small"
                  key={skillKey}
                  fontWeight={fontWeights.medium}
                  style={{ gap: space.xxs.value }}
                >
                  <LynxIcon label={skill.name} />
                  <Text as="p">{skill.name}</Text>
                </Badge>
              ),
          )}
        </Flex>
      </View>
    ));
  }

  return (
    <Grid templateColumns="1fr 1fr" gap={space.medium}>
      <Flex padding={"0"} gap={"0"} direction="column">
        <Flex justifyContent="space-between">
          <Heading level={5}>
            {shouldShowNewAttributeEditor ? "New Skill" : "All Attributes"}
          </Heading>
          <Button
            gap={space.xxxs}
            padding={"0"}
            onClick={() => setShouldShowNewAttributeEditor((state) => !state)}
          >
            {shouldShowNewAttributeEditor ? "- Cancel" : "+ Add New Attribute"}
          </Button>
        </Flex>
        {!shouldShowNewAttributeEditor ? (
          <Flex
            backgroundColor={colors.gray[10]}
            borderRadius={space.xxs}
            maxHeight="400px"
            height="400px"
            as="ul"
            padding={space.xxs}
            style={{ listStyleType: "none" }}
            overflow="auto"
            margin={`${space.xxs} 0 0`}
            gap={space.medium}
            direction="column"
          >
            <LynxSuspense>{renderSkillCheckbox()}</LynxSuspense>
          </Flex>
        ) : (
          <NewAttributeEditor callBack={addNewCustomAttribute} />
        )}
      </Flex>
      <Flex padding={"0"} gap={space.xxs} direction="column">
        <Heading level={5}>My Attributes</Heading>
        <Flex direction="column" gap={space.medium} padding={"0"} wrap="wrap">
          {renderUserSkillCloud()}
        </Flex>
      </Flex>
    </Grid>
  );
}
