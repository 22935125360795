import React, { useEffect, useState } from "react";
import { TextField, Button } from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";
import { LynxIcon } from "./atoms/";

export default function SearchUserInput({
  label = "Search",
  placeholder = "",
  callBack,
}: {
  callBack: (param: string) => void;
  label?: string;
  placeholder?: string;
}) {
  const {
    tokens: { colors, space },
  } = useLynxTheme();
  const [searchInputValue, setSearchInputValue] = useState("");

  useEffect(() => {
    callBack(searchInputValue);
  }, [callBack, searchInputValue]);

  return (
    <TextField
      inputStyles={{
        backgroundColor: colors.white.value,
      }}
      gap={space.xxxs}
      size="small"
      label={label}
      placeholder={placeholder}
      value={searchInputValue}
      isRequired={true}
      onChange={(event: React.FormEvent<HTMLInputElement>) =>
        setSearchInputValue(event.currentTarget.value)
      }
      innerEndComponent={
        searchInputValue.length > 0 ? (
          <Button
            style={{
              paddingLeft: space.xxs.value,
              paddingRight: space.xxs.value,
            }}
            onClick={() => setSearchInputValue("")}
            ariaLabel="Clear"
          >
            <LynxIcon label="FaClose" />
          </Button>
        ) : null
      }
    />
  );
}
