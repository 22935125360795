import React from "react";
import { useNavigate } from "react-router-dom";
import { useURLQuery } from "../hooks";
import { Flex, Button, View, useTheme } from "@aws-amplify/ui-react";
import { LynxIcon } from "./atoms";

type PropTypes = {
  children: React.ReactNode;
};

export default function ModalHeader({ children }: PropTypes) {
  const navigate = useNavigate();
  const urlQuery = useURLQuery();
  const {
    tokens: { space },
  } = useTheme();

  const closeModal = () => {
    urlQuery.delete("id");
    navigate(urlQuery.toString());
  };

  return (
    <Flex justifyContent="space-between" margin={`0 0 ${space.medium} 0`}>
      <View>{children}</View>
      <Button padding={"0"} onClick={closeModal} size="large">
        <LynxIcon label="Close" />
      </Button>
    </Flex>
  );
}
