import { Routes, Route } from "react-router-dom";
import useURLQuery from "./hooks/useURLQuery";
import { Header, UserProfile, Modal } from "./components";
import { People, Attributes, NotFound } from "./pages/";
import {
  useAuthorization,
  UserAccountContextProvider,
  UserSkillsContextProvider,
} from "./context";
import "./styles/base.scss";
import "@aws-amplify/ui/styles.css";
import { Login, LoadingScreen } from "./components";
import { View } from "@aws-amplify/ui-react";

function App() {
  const urlQuery = useURLQuery();
  const targetedUserId = urlQuery.get("id");
  const { isAuthenticating, isLoggedIn, error, OAuthProfile } =
    useAuthorization();

  if (isAuthenticating) {
    return <h1>...Authenticating</h1>;
  }

  if (error || !isLoggedIn || !OAuthProfile) {
    return <Login errorState={error} />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <UserAccountContextProvider OAuthProfile={OAuthProfile}>
              <View
                overflow={targetedUserId ? "hidden" : "auto"}
                maxHeight={targetedUserId ? "100vh" : "unset"}
              >
                <Header />
                <UserSkillsContextProvider>
                  <People />
                </UserSkillsContextProvider>
              </View>
            </UserAccountContextProvider>
          }
        />
        <Route path="attributes" element={<Attributes />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {targetedUserId && (
        <Modal>
          <UserProfile
            OAuthProfileEmail={OAuthProfile.email}
            targetedUserId={targetedUserId}
          />
        </Modal>
      )}

      <LoadingScreen delay={1500} />
    </>
  );
}

export default App;
