import { Auth } from "aws-amplify";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { View, Flex, Image, Heading, Text } from "@aws-amplify/ui-react";
import { SpinningLogo } from "./";
import { LynxButton } from "./atoms/";
import { useLynxTheme } from "../hooks";
import { AuthorizationError } from "../interface";
import { AUTHORIZATION_MESSAGE } from "../util/authorizationUtil";

type PropTypes = {
  errorState?: AuthorizationError | null;
};

function Login({ errorState = null }: PropTypes) {
  const {
    tokens: { fontSizes, colors, columns, space },
  } = useLynxTheme();

  return (
    <View position="fixed" width="100%" height="100%" top={"0"} left={"0"}>
      <Flex
        backgroundColor={colors.gray[10]}
        padding={`0`}
        gap={"0"}
        justifyContent="space-between"
        direction="row"
      >
        <View borderRadius="0 100px 100px 0" overflow="hidden">
          <SpinningLogo
            background={colors.brand.primary}
            scale={2}
            width={columns[7].value}
          />
        </View>
        <Flex
          width={columns[5].value}
          gap={"0"}
          padding={`0 ${space.xxl}`}
          justifyContent="center"
          direction="column"
        >
          <Heading level={2}>Welcome to Lynx</Heading>
          <LynxButton
            size="medium"
            theme="secondary"
            margin={`${space.xxs} 0`}
            onClick={() =>
              Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Google,
              })
            }
          >
            <Flex alignItems="center" gap={space.xxxs}>
              <Image
                height={space.large}
                src="googleLogo.png"
                alt="Google Logo"
              />
              Sign in with Google
            </Flex>
          </LynxButton>
          {errorState && (
            <Text fontSize={fontSizes.medium} color={"red"}>
              {AUTHORIZATION_MESSAGE[errorState]}
            </Text>
          )}
        </Flex>
      </Flex>
    </View>
  );
}

export default Login;
