import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useURLQuery, useLynxTheme } from "../hooks";
import { Flex, Card, View } from "@aws-amplify/ui-react";

type PropTypes = {
  parentElement?: HTMLElement;
  children: React.ReactNode;
};

export default function Modal({
  children,
  parentElement = document.body,
}: PropTypes) {
  const navigate = useNavigate();
  const urlQuery = useURLQuery();
  const {
    tokens: { colors, space, zIndex },
  } = useLynxTheme();

  useEffect(() => {
    parentElement.classList.add("overflow-hidden");

    return () => {
      parentElement.classList.remove("overflow-hidden");
    };
  }, [parentElement.classList]);

  const closeModal = () => {
    urlQuery.delete("id");
    navigate(urlQuery.toString());
  };

  const OverlayBackground = () => {
    return (
      <View
        backgroundColor={colors.gray[10]}
        width="100%"
        height="100%"
        position="absolute"
        opacity={"0.4"}
        top={"0"}
        left={"0"}
        onClick={closeModal}
      />
    );
  };

  const ModalWrapper = ({ children }: { children: React.ReactNode }) => {
    return (
      <View
        position="fixed"
        width="100%"
        height="100%"
        top={"0"}
        left={"0"}
        style={{
          zIndex: zIndex.overlay,
        }}
      >
        {children}
      </View>
    );
  };

  return (
    <ModalWrapper>
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="flex-start"
        padding={`${space.small}`}
      >
        <Card
          width="100%"
          maxWidth="1024px"
          height="95vh"
          variation="elevated"
          overflow="auto"
          style={{ zIndex: 1 }}
        >
          {children}
        </Card>
        <OverlayBackground />
      </Flex>
    </ModalWrapper>
  );
}
