import React, { useState } from "react";
import { SelectField, TextField, Flex, useTheme } from "@aws-amplify/ui-react";
import { LynxButton } from "./atoms";
import {
  getProfileTypesKeyByValue,
  PROFILE_TYPES_NAME,
} from "../util/skillsDataUtil";
import { ProfileTypesEnum, SkillInterface } from "../interface";

const TYPES_EMPTY_OPTION = "-";

enum UPDATE_SKILL_DATA_TYPE {
  NAME,
  TYPES,
  DESC,
}

type PropType = {
  callBack: (attribute: SkillInterface) => void;
};

type NewAttributeType = {
  name: string;
  types: string | ProfileTypesEnum;
  desc: string;
  isNewSkill: boolean;
};

export default function NewAttributeEditor({ callBack }: PropType) {
  const {
    tokens: { space },
  } = useTheme();
  const [newSkill, setNewSkill] = useState<NewAttributeType>({
    name: "",
    types: TYPES_EMPTY_OPTION,
    desc: "",
    isNewSkill: false,
  });

  function validateNewSkillData() {
    return newSkill.name !== "" && newSkill.types !== TYPES_EMPTY_OPTION;
  }

  function updateNewSkillData(
    data: string | ProfileTypesEnum,
    type: UPDATE_SKILL_DATA_TYPE,
  ): NewAttributeType {
    return {
      name: type === UPDATE_SKILL_DATA_TYPE.NAME ? data : newSkill.name,
      types:
        type === UPDATE_SKILL_DATA_TYPE.TYPES
          ? (data as ProfileTypesEnum)
          : newSkill.types,
      desc: type === UPDATE_SKILL_DATA_TYPE.DESC ? data : newSkill.desc,
      isNewSkill: false,
    };
  }

  function saveNewSkill() {
    newSkill.isNewSkill = true;
    callBack(newSkill as SkillInterface);
  }

  return (
    <Flex gap={space.xxs} direction="column">
      <TextField
        label="Name*"
        as="input"
        autoComplete="off"
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          setNewSkill(
            updateNewSkillData(
              event.currentTarget.value,
              UPDATE_SKILL_DATA_TYPE.NAME,
            ),
          )
        }
      />
      <SelectField
        label="Types*"
        placeholder={TYPES_EMPTY_OPTION}
        onChange={(event: React.FormEvent<HTMLSelectElement>) => {
          const profileType = getProfileTypesKeyByValue(
            event.currentTarget.value,
            PROFILE_TYPES_NAME,
          );
          setNewSkill(
            updateNewSkillData(
              profileType
                ? (profileType as keyof typeof ProfileTypesEnum)
                : TYPES_EMPTY_OPTION,
              UPDATE_SKILL_DATA_TYPE.TYPES,
            ),
          );
        }}
      >
        {Object.values(PROFILE_TYPES_NAME).map((type, key) => (
          <option key={key} value={type}>
            {type}
          </option>
        ))}
      </SelectField>
      <TextField
        as="textarea"
        label="Description"
        autoComplete="off"
        onChange={(event: React.FormEvent<HTMLTextAreaElement>) =>
          setNewSkill(
            updateNewSkillData(
              event.currentTarget.value,
              UPDATE_SKILL_DATA_TYPE.DESC,
            ),
          )
        }
      />
      <LynxButton
        theme="primary"
        isDisabled={!validateNewSkillData()}
        onClick={() => saveNewSkill()}
      >
        Add New Attribute
      </LynxButton>
    </Flex>
  );
}
