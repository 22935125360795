import { useState } from "react";
import Logo from "./Logo";
import { LynxParagraph, LynxIcon, LynxIconButton, LynxRadio } from "./atoms/";
import {
  Grid,
  Flex,
  Heading,
  View,
  RadioGroupField
} from "@aws-amplify/ui-react";
import { Template } from "./";
import { TEMPLATE_TYPES } from "./Template";
import { useLynxTheme, useWindowSize } from "../hooks";
import UserOperation from "../api/UserOperation";
import { UserTypesEnum } from "../interface/";

type PropType = {
  firstName: string;
  lastName: string;
  email: string;
};

type StatusType = {
  isInComplete: boolean;
  isProcessing: boolean;
};

export default function Onboarding({ firstName, lastName, email }: PropType) {
  const [userType, setUserType] = useState<string | undefined>();
  const [status, setStatus] = useState<StatusType>({
    isInComplete: false,
    isProcessing: false,
  });
  const userOperation = new UserOperation();
  const {
    breakpoints,
    tokens: { space, colors, columns, fontSizes },
  } = useLynxTheme();
  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth && windowWidth < breakpoints.values.medium;

  async function handleCreateUser() {
    if (!userType) {
      setStatus((prevStatus: StatusType) => {
        return {
          ...prevStatus,
          isInComplete: true,
        };
      });
      return;
    }
    setStatus(() => {
      return {
        isInComplete: false,
        isProcessing: true,
      };
    });
    try {
      const newUser = await userOperation.createUser(
        firstName,
        lastName,
        email,
        userType,
      );
      if (newUser) {
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
      throw new Error(`not able to create user with: {
        firstName: ${firstName},
        lastName: ${lastName},
        email: ${email},
        userType: ${userType}
      }`);
    }
  }

  function getNextCTA() {
    const getMobileIconButtonStyles = () => {
      return isMobile
        ? {
            width: "100%",
            borderRadius: 0,
          }
        : null;
    };
    const iconbutton = (
      <LynxIconButton
        {...getMobileIconButtonStyles()}
        size={"large"}
        isDisabled={status.isProcessing}
        onClick={handleCreateUser}
        icon={<LynxIcon label="rightArrow"/>}
      />
    );

    return !isMobile ? (
      <Flex alignItems={"center"} justifyContent={"center"}>
        {iconbutton}
      </Flex>
    ) : (
      <Flex
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
        }}
      >
        {iconbutton}
      </Flex>
    );
  }

  function getTemplateColumns() {
    return {
      templateColumns: !isMobile
        ? `${columns[10]} ${columns[2]}`
        : `${columns[12]}`,
    };
  }

  return (
    <Template
      alignItems="center"
      variants={TEMPLATE_TYPES.FULL_WIDTH}
      backgroundColor={colors.gray[10]}
      height={columns[12].value}
      position="fixed"
      top={"0"}
      left={"0"}
    >
      <Grid {...getTemplateColumns()}>
        <View>
          <Logo />
          <LynxParagraph margin={`${space.xxs} 0`} color={colors.gray[20]}>
            {email}
          </LynxParagraph>
          <Heading margin={`0 0 ${space.xxs}`} fontSize={fontSizes.xxxxl}>
            {!status.isProcessing ? (
              <>
                Welcome {firstName},{<br />}which option defines you better.
              </>
            ) : (
              `Creating your profile ...`
            )}
          </Heading>
          <LynxParagraph>You can always change it later.</LynxParagraph>
          {status.isInComplete && (
            <LynxParagraph theme="error">
              Please select an option first.
            </LynxParagraph>
          )}
          <RadioGroupField
            label=""
            name="userFlowTypes"
            gap={space.xs}
            onChange={(event: React.FormEvent<HTMLInputElement>) =>
              setUserType(event.currentTarget.value)
            }
            isDisabled={status.isProcessing}
          >
            <LynxRadio value={UserTypesEnum.RESOURCE} size="large">
              <LynxParagraph fontSize={fontSizes.xl}>
                I&apos;m looking for a technical resource
              </LynxParagraph>
            </LynxRadio>
            <LynxRadio value={UserTypesEnum.DEV} size="large">
              <LynxParagraph fontSize={fontSizes.xl}>
                I want to add my profile
              </LynxParagraph>
            </LynxRadio>
          </RadioGroupField>
        </View>
        {getNextCTA()}
      </Grid>
    </Template>
  );
}
