import { useState } from "react";
import { TagsInput } from "./";
import { useUserSkills } from "../context";
import { Heading, Flex, View, Grid,Text, useTheme } from "@aws-amplify/ui-react";
import {
  groupSkillsByType,
  getProfileColorByType,
  PROFILE_TYPES_NAME,
  CONFLICTING_ATTRIBUTES,
} from "../util/skillsDataUtil";
import { UserRolesName, UserLevelsName } from "../util/userDataUtil";
import {
  ProfileTypesEnum,
  SkillInterface,
  SkillsFilterOptions,
} from "../interface";
import LynxIcon from "./atoms/LynxIcon/";
type PropType = {
  callBack: (params: Array<SkillsFilterOptions>) => void;
};

type TagsFilterOptions = {
  label: string;
  value: string;
  icon: string;
} & SkillsFilterOptions;

const UserRoleAndLevelFilter = {
  Level: Object.values(UserLevelsName),
  Role: Object.values(UserRolesName),
};

type searchFilterType = ProfileTypesEnum | "Role" | "Level";

export default function UserAttributeTagsInputList({ callBack }: PropType) {
  const { userSkillsContext } = useUserSkills();
  const userSkills: Array<SkillInterface> = userSkillsContext;
  const groupedSkills = groupSkillsByType(userSkills) as {
    [key in ProfileTypesEnum]: Array<SkillInterface>;
  };
  const [searchFilter, setSearchFilter] = useState<
    Map<searchFilterType, Array<TagsFilterOptions>>
  >(new Map());
  const {
    tokens: { space, fontSizes },
  } = useTheme();

  function updateFilter(
    options: Array<TagsFilterOptions>,
    attrType: searchFilterType,
  ) {
    let searchFilterResult: Array<SkillsFilterOptions> = [];
    setSearchFilter((filterMap) => {
      filterMap.set(attrType, options);
      searchFilterResult = Array.from(filterMap.values())
        .flat()
        .filter((value) => value);
      return filterMap;
    });
    callBack(searchFilterResult);
  }

  return (
    <Flex direction="column" gap={space.medium}>
      <Grid templateColumns=".7fr 1fr" gap={space.xxxs}>
        {Object.keys(UserRoleAndLevelFilter).map((objKey, key) => {
          const typedObjKey = objKey as keyof typeof UserRoleAndLevelFilter;
          return (
            <View key={key}>
              <Flex
                gap={space.xxxs}
                margin={`0 0 ${space.xxxs} 0`}
                alignItems="center"
              >
                <Text  fontSize={fontSizes.small}>
                  {objKey}
                </Text>
              </Flex>
              <TagsInput
                isClearable={true}
                options={Object.values(UserRoleAndLevelFilter[typedObjKey]).map(
                  (name) => {
                    return {
                      label: name,
                      value: name,
                      name: name,
                      types: objKey,
                    };
                  },
                )}
                onChange={(options) =>
                  updateFilter(options as Array<TagsFilterOptions>, typedObjKey)
                }
              />
            </View>
          );
        })}
      </Grid>

      {Object.keys(groupedSkills).map((objKey, key) => {
        const typedObjKey = objKey as ProfileTypesEnum;
        return (
          <View key={key}>
            <Flex
              gap={space.xxxs}
              margin={`0 0 ${space.xxs} 0`}
              alignItems="center"
            >
              <View
                width={space.xxs}
                height={space.xxs}
                borderRadius="50%"
                backgroundColor={`${getProfileColorByType(typedObjKey)}`}
              />
              <Heading level={4} fontSize={fontSizes.small}>
                {PROFILE_TYPES_NAME[typedObjKey]}
              </Heading>
            </Flex>
            <TagsInput
              isMulti
              closeMenuOnSelect={false}
              options={groupedSkills[objKey as ProfileTypesEnum]
                .map(({ name, _deleted }) => {
                  return !_deleted &&
                    searchFilter &&
                    Array.from(searchFilter.values())
                      .flat()
                      .find(
                        (filter) =>
                          filter &&
                          filter.name === name &&
                          filter.types ===
                            Object.keys(CONFLICTING_ATTRIBUTES)[
                              CONFLICTING_ATTRIBUTES[typedObjKey]
                            ],
                      ) === undefined
                    ? {
                        label: name,
                        value: name,
                        name: name,
                        icon: <LynxIcon label={name} size={"small"} />,
                        types: objKey,
                      }
                    : null;
                })
                .filter((option) => option)}
              onChange={(options) =>
                updateFilter(options as Array<TagsFilterOptions>, typedObjKey)
              }
            />
          </View>
        );
      })}
    </Flex>
  );
}
