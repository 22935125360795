import React from "react";
import { Button } from "@aws-amplify/ui-react";
import "./style.scss";

/**
 * @component LynxButton
 * @description
 * A branded wrapper button component for the Lynx design system
 * @param {{'primary', 'secondary'}} [theme]
 * @param {String} [className]
 * @returns
 */

export enum LynxButtonThemeEnum {
  primary,
  secondary,
}

export const LNYX_BUTTON_THEMES = {
  [LynxButtonThemeEnum.primary]: "primary",
  [LynxButtonThemeEnum.secondary]: "secondary",
};

type PropTypes = {
  theme: LynxButtonThemeEnum;
  className: string;
  children: React.ReactNode;
} & typeof Button.arguments;

export default function LynxButton({
  theme,
  className,
  children,
  ...otherProps
}: PropTypes) {
  return (
    <Button className={`lynx-button ${theme} ${className}`} {...otherProps}>
      {children}
    </Button>
  );
}
