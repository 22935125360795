import { sanitizeString } from "./stringOperation";
import {
  ProfileTypesEnum,
  SkillInterface,
  SkillsFilterOptions,
} from "../interface/";
import { lynxTheme } from "../styles/themeConfig";

type groupSkillObjectType = {
  [key: string]: Array<SkillInterface>;
};

export const PROFILE_TYPES_NAME = {
  [ProfileTypesEnum.SKILL]: "Skill",
  [ProfileTypesEnum.AREAS_OF_INTEREST]: "Areas of Interest",
  [ProfileTypesEnum.AREAS_TO_AVOID]: "Areas to avoid",
  [ProfileTypesEnum.CLIENTS_MATCH]: "Clients Match",
  [ProfileTypesEnum.CLIENTS_TO_AVOID]: "Clients to avoid",
  [ProfileTypesEnum.IDEAL_PROJECT_LENGTH]: "Ideal Project Length",
};

export const CONFLICTING_ATTRIBUTES: { [key in ProfileTypesEnum]: number } = {
  [ProfileTypesEnum.AREAS_TO_AVOID]: 1,
  [ProfileTypesEnum.AREAS_OF_INTEREST]: 0,
  [ProfileTypesEnum.CLIENTS_MATCH]: 3,
  [ProfileTypesEnum.CLIENTS_TO_AVOID]: 2,
  [ProfileTypesEnum.SKILL]: -1,
  [ProfileTypesEnum.IDEAL_PROJECT_LENGTH]: -1,
};

function getProfileColorByType(profileType: ProfileTypesEnum) {
  const {
    tokens: {
      colors: {
        skill,
        clientMatch,
        clientToAvoid,
        areasOfInterest,
        areasToAvoid,
        idealProjectLength,
      },
    },
  } = lynxTheme;

  return {
    SKILL: skill,
    AREAS_OF_INTEREST: areasOfInterest,
    AREAS_TO_AVOID: areasToAvoid,
    CLIENTS_MATCH: clientMatch,
    CLIENTS_TO_AVOID: clientToAvoid,
    IDEAL_PROJECT_LENGTH: idealProjectLength,
  }[profileType].value;
}

function groupSkillsByType(skills: Array<SkillInterface>) {
  const typesObj: groupSkillObjectType = {};
  for (let i = 0; i < skills.length; i++) {
    if (!typesObj[skills[i].types]) {
      typesObj[skills[i].types] = [skills[i]];
    } else {
      typesObj[skills[i].types].push(skills[i]);
    }
  }

  return typesObj;
}

function hasExactAttributeMatch(
  attribute: SkillInterface,
  attributes: Array<SkillsFilterOptions>,
) {
  return !attributes.every(({ name, types }) => {
    if (types === attribute.types) {
      return sanitizeString(name) !== sanitizeString(attribute.name);
    }
    return true;
  });
}

function hasPartialAttributeMatch(
  newAttribute: string,
  oldAttributes: Array<string>,
): Array<string> {
  const newAttributeParts = splitStringBySpaces(newAttribute);
  return oldAttributes
    .map((oldAttribute: string) =>
      findMatchesFromTwoArray(
        newAttributeParts,
        splitStringBySpaces(oldAttribute),
      ).length > 0
        ? oldAttribute
        : null,
    )
    .filter((attr) => attr) as Array<string>;
}

function splitStringBySpaces(value: string) {
  return value.split(" ").filter((item) => item.trim().length !== 0);
}

function findMatchesFromTwoArray(arr1: Array<string>, arr2: Array<string>) {
  return arr1.filter((value) => arr2.includes(value));
}

function getProfileTypesKeyByValue(
  value: string,
  object: { [key: string]: string },
) {
  return Object.keys(object).find((key) => object[key] === value);
}

export {
  hasExactAttributeMatch,
  hasPartialAttributeMatch,
  getProfileTypesKeyByValue,
  getProfileColorByType,
  groupSkillsByType,
};
