import { SelectField } from "@aws-amplify/ui-react";
import "./style.scss";

type PropTypes = typeof SelectField.arguments;

export default function LynxDropdown({ children, ...otherProps }: PropTypes) {
  return (
    <SelectField className="lynx-dropdown" {...otherProps}>
      {children}
    </SelectField>
  );
}
