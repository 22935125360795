import { Button, Flex, Text, Heading } from "@aws-amplify/ui-react";
import { useLynxTheme, useURLQuery } from "../hooks";
import ProfileImage from "./ProfileImage";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useUserAccount } from "../context";
import { LynxIcon } from "./atoms/";

import Logo from "./Logo";

export default function Header() {
  const navigate = useNavigate();
  const urlQuery = useURLQuery();
  const userAccount = useUserAccount();
  const { id, firstName, lastName, title, email } = userAccount;
  const {
    tokens: { colors, columns, fontSizes, fontWeights, space },
  } = useLynxTheme();

  return (
    <Flex
      alignItems="center"
      backgroundColor={colors.brand.primary}
      width={columns[12].value}
      height={space.xxxl}
      padding={`0 ${space.large}`}
      gap={"0"}
    >
      <Flex width={space.xxxl} margin={`0 ${space.large} 0 0`}>
        <Logo color={colors.white} />
      </Flex>
      <Button
        justifyContent="flex-start"
        padding={`${space.xxs} ${space.medium}`}
        onClick={() => {
          urlQuery.set("id", id);
          navigate({
            search: urlQuery.toString(),
          });
        }}
      >
        <Flex
          gap={space.xs}
          direction="row"
          alignItems={"center"}
          color={colors.white}
        >
          <ProfileImage
            email={email}
            boxShadow={`0 0 0 1px ${colors.white}`}
            size={40}
          />
          <Flex gap="0" direction="column">
            <Heading color={colors.white} level={4}>
              {firstName} {lastName}
            </Heading>
            <Text
              color={colors.white}
              fontSize={fontSizes.small}
              fontWeight={fontWeights.normal}
            >
              {title}
            </Text>
          </Flex>
          <LynxIcon label={"edit"} />
        </Flex>
      </Button>
      <Button color={colors.white} onClick={() => Auth.signOut()}>
        Sign out
      </Button>
    </Flex>
  );
}
