// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ProfileTypes = {
  "SKILL": "SKILL",
  "AREAS_OF_INTEREST": "AREAS_OF_INTEREST",
  "AREAS_TO_AVOID": "AREAS_TO_AVOID",
  "CLIENTS_MATCH": "CLIENTS_MATCH",
  "CLIENTS_TO_AVOID": "CLIENTS_TO_AVOID",
  "IDEAL_PROJECT_LENGTH": "IDEAL_PROJECT_LENGTH"
};

const UserRoleEnum = {
  "FRONT_END_DEVELOPER": "FRONT_END_DEVELOPER",
  "BACK_END_DEVELOPER": "BACK_END_DEVELOPER",
  "FULL_STACK_DEVELOPER": "FULL_STACK_DEVELOPER",
  "CREATIVE_DEVELOPER": "CREATIVE_DEVELOPER",
  "DEV_OPS_DEVELOPER": "DEV_OPS_DEVELOPER",
  "ASSOCIATE_TECHNICAL_DIRECTOR": "ASSOCIATE_TECHNICAL_DIRECTOR",
  "TECHNICAL_DIRECTOR": "TECHNICAL_DIRECTOR",
  "ASSOCIATE_TECHNICAL_ARCHITECT": "ASSOCIATE_TECHNICAL_ARCHITECT",
  "TECHNICAL_ARCHITECT": "TECHNICAL_ARCHITECT"
};

const UserStatusEnum = {
  "AVAILABLE": "AVAILABLE",
  "UNAVAILABLE": "UNAVAILABLE"
};

const UserTypeEnum = {
  "RESOURCE": "RESOURCE",
  "DEV": "DEV"
};

const UserLevelEnum = {
  "JUNIOR": "JUNIOR",
  "INTERMEDIATE": "INTERMEDIATE",
  "SENIOR": "SENIOR",
  "DIRECTOR": "DIRECTOR"
};

const LocationEnum = {
  "AMSTERDAM": "AMSTERDAM",
  "MONTEVIDEO": "MONTEVIDEO",
  "LOS_ANGELES": "LOS_ANGELES",
  "TORONTO": "TORONTO",
  "NEW_YORK": "NEW_YORK"
};

const { Skill, User, SkillUser } = initSchema(schema);

export {
  Skill,
  User,
  SkillUser,
  ProfileTypes,
  UserRoleEnum,
  UserStatusEnum,
  UserTypeEnum,
  UserLevelEnum,
  LocationEnum
};