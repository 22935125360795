import React from "react";
import { Text } from "@aws-amplify/ui-react";
import { useLynxTheme } from "../../../hooks";

/**
 * @component LynxParagraph
 * @description
 * A branded wrapper paragraph component for the Lynx design system
 * @param {{'primary', 'secondary', 'tertiary', 'error', 'warning', 'info', 'success'}} [theme]
 * @param {{1, 2, 3}} [level]
 * @returns
 */

type PropTypes = {
  theme:
    | "primary"
    | "secondary"
    | "tertiary"
    | "error"
    | "warning"
    | "info"
    | "success";
  level: 1 | 2 | 3;
  children: React.ReactNode;
} & typeof Text.arguments;

export default function LynxParagraph({
  theme,
  level,
  children,
  ...otherProps
}: PropTypes) {
  const {
    tokens: { fontSizes, colors },
  } = useLynxTheme();

  function getVariation() {
    switch (theme) {
      case "primary":
        return {
          color: colors.brand.primary,
        };
      case "secondary":
        return {
          color: colors.brand.secondary,
        };
      case "tertiary":
        return {
          color: colors.brand.tertiary,
        };
      case "error":
        return {
          color: colors.brand.error,
        };
      default:
        return {
          color: colors.brand.primary,
        };
    }
  }

  function getFontSize() {
    switch (level) {
      case 1:
        return fontSizes.large.value;
      case 2:
        return fontSizes.medium.value;
      case 3:
        return fontSizes.small.value;
      default:
        return fontSizes.medium.value;
    }
  }

  return (
    <Text as="p" fontSize={getFontSize()} {...getVariation()} {...otherProps}>
      {children}
    </Text>
  );
}
