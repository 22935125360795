import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from "react";
import SkillOperation from "../api/SkillOperation";
import { Text } from "@aws-amplify/ui-react";
import { SkillInterface } from "../interface";
import { DataEvents, EventsName } from "../events/index";

const UserSkillsContext = createContext<{
  userSkills: Array<SkillInterface>;
  fetchUserSkills: () => void;
}>({
  userSkills: [],
  fetchUserSkills: () => null,
});

export const useUserSkills = () => {
  const context = useContext(UserSkillsContext);

  if (!context) {
    throw new Error(
      "useUserSkills must be used within a UserSkillsContextProvider",
    );
  }

  const { userSkills, fetchUserSkills } = context;

  return {
    userSkillsContext: userSkills,
    updateUserSkillsContext: fetchUserSkills,
  };
};

export const UserSkillsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userSkills, setUserSkills] = useState<Array<SkillInterface>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchUserSkills = useCallback(async () => {
    setIsLoading(true);
    setUserSkills(await new SkillOperation().getSkills());
    setIsLoading(false);
  }, []);
  const contextValues = useMemo(() => {
    return {
      userSkills,
      fetchUserSkills,
    };
  }, [userSkills, fetchUserSkills]);

  useEffect(() => {
    const userSkillsContextChannel = new DataEvents(
      EventsName.REFRESH_SKILLS_CONTEXT, () => {
        fetchUserSkills();
      }
    );

    (async () => {
      await fetchUserSkills();   
    })();

    return () => {
      userSkillsContextChannel.close();
    }
  }, [fetchUserSkills]);

  return !isLoading ? (
    <UserSkillsContext.Provider value={contextValues}>
      {children}
    </UserSkillsContext.Provider>
  ) : (
    <Text>fetching all skills</Text>
  );
};
