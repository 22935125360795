import { useState, useEffect } from "react";
import { UserAttributeTagsInputList, SearchUsersInput } from "./";
import { Flex, View } from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";
import { SearchFilterInterface, SkillsFilterOptions } from "../interface";

export default function SideBar({
  callBack,
}: {
  callBack: ({ searchField, skillsFilter }: SearchFilterInterface) => void;
}) {
  const {
    tokens: { colors, columns, space },
  } = useLynxTheme();
  const [searchField, setSearchField] = useState("");
  const [skillsFilter, setSkillsFilter] = useState<Array<SkillsFilterOptions>>(
    [],
  );

  useEffect(() => {
    callBack({
      searchField,
      skillsFilter,
    });
  }, [callBack, searchField, skillsFilter]);

  return (
    <Flex
      display="inline-flex"
      width={columns[12].value}
      height={`calc(100vh - var(--nav-height))`}
      padding={`${space.medium} ${space.large}`}
      backgroundColor={colors.gray[10]}
      gap={"0"}
      overflow="scroll"
    >
      <Flex direction="column" gap={"0"} width={columns[12].value}>
        <SearchUsersInput
          callBack={setSearchField}
          label="Name"
          placeholder="Try a name and/or surname ..."
        />
        <View paddingTop={`${space.small}`} paddingBottom={`${space.medium}`}>
          <UserAttributeTagsInputList callBack={setSkillsFilter} />
        </View>
      </Flex>
    </Flex>
  );
}
