import { API, graphqlOperation } from "aws-amplify";
import {
  ErrorInterface,
  UserInterface,
  LocationsEnum,
  UserTypesEnum,
  UserLevelsEnum,
} from "../interface";
class UserOperation {
  async getUser(userId: string) {
    try {
      const { data } = (await API.graphql(
        graphqlOperation(
          `
          query GetUser($id: ID!) {
            getUser(id: $id) {
              firstName
              id
              lastName
              type
              location
              level
              skills {
                items {
                  skill {
                    id
                    name
                    desc
                    types
                    _version
                  }
                }
              }
              updatedAt
              title
              status
              _version
              _lastChangedAt
              desc
              email
              createdAt
              manager
            }
          }
          `,
          { id: userId },
        ),
      )) as {
        data: {
          getUser: UserInterface;
        };
      };

      return data.getUser;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async getUsers() {
    try {
      const {
        data: { listUsers },
      } = (await API.graphql(
        graphqlOperation(
          `
          query GetUsers {
            listUsers {
              items {
                id
                firstName
                lastName
                type
                location
                level
                email
                role
                title
                desc
                status
                _deleted
                _version
                _lastChangedAt
                skills {
                  items {
                    skill {
                      id
                      name
                      desc
                      types
                    }
                  }
                }
              }
            }
          }
        `,
        ),
      )) as {
        data: {
          listUsers: { items: Array<UserInterface> };
        };
      };

      return listUsers.items;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async updateUser(
    userId: string,
    {
      title,
      desc,
      type,
      location,
      level,
      version,
    }: {
      title: string;
      desc: string;
      type: UserTypesEnum;
      location: LocationsEnum;
      level: UserLevelsEnum;
      version: number;
    },
  ) {
    try {
      const {
        data: { updateUser },
      } = (await API.graphql(
        graphqlOperation(
          `
          mutation UpdateUser(
            $id: ID!,
            $version: Int!,
            $desc: String,
            $title: String,
            $type: UserTypeEnum,
            $level: UserLevelEnum,
            $location: LocationEnum
          ) {
            updateUser(input: {
              id: $id,
              _version: $version,
              desc: $desc,
              title: $title,
              type: $type,
              level: $level,
              location: $location
            }) {
              id
              desc
              title
              type
              level
              location
              _version
            }
          }
        `,
          {
            id: userId,
            version,
            title,
            desc,
            type,
            level,
            location,
          },
        ),
      )) as {
        data: { updateUser: object };
      };

      return updateUser;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async updateUserSkills(
    userId = "",
    skills = [
      {
        id: "",
        name: "",
        desc: "",
        isNewSkill: false,
        removedFromUser: false,
      },
    ],
  ) {
    try {
      const {
        data: { updateUserSkills },
      } = (await API.graphql(
        graphqlOperation(
          `
          mutation UpdateUserSkill($userId: ID!, $skills: [UpdateUserSkillsType!]!) {
            updateUserSkills(input: {userId: $userId, skills: $skills})
          }          
        `,
          {
            userId,
            skills,
          },
        ),
      )) as {
        data: { updateUserSkills: object };
      };

      return updateUserSkills;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async updateUserNewSkills(
    userId = "",
    skills = [
      {
        id: "",
        name: "",
        desc: "",
        isNewSkill: false,
        removedFromUser: false,
      },
    ],
  ) {
    try {
      const {
        data: { updateUserNewSkills },
      } = (await API.graphql(
        graphqlOperation(
          `
          mutation UpdateUserNewSkill($userId: ID!, $skills: [UpdateUserSkillsType!]!) {
            updateUserNewSkills(input: {userId: $userId, skills: $skills})
          }          
        `,
          {
            userId,
            skills,
          },
        ),
      )) as {
        data: { updateUserNewSkills: object };
      };

      return updateUserNewSkills;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async createUser(
    firstName: string,
    lastName: string,
    email: string,
    type: string,
  ) {
    try {
      const {
        data: { createUser },
      } = (await API.graphql(
        graphqlOperation(
          `
          mutation CreateUser($firstName: String!, $lastName: String!, $email: AWSEmail!, $type: UserTypeEnum) {
            createUser(input: {firstName: $firstName, lastName: $lastName, email: $email, type: $type}) {
              firstName,
              lastName,
              email,
              type
            }
          }
        `,
          {
            firstName,
            lastName,
            email,
            type,
          },
        ),
      )) as {
        data: {
          createUser: object;
        };
      };

      return createUser;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async findUserByEmail(email = "") {
    try {
      const {
        data: { findUserByEmail },
      } = (await API.graphql(
        graphqlOperation(
          `
          query FindUserByEmail($email: String!) {
            findUserByEmail(email: $email) {
              id
              firstName
              lastName
              type
              location
              level
              email
              title
              desc
              status
              _deleted
              _version
              skills {
                items {
                  skill {
                    id
                    name
                    desc
                    types
                  }
                }
              }
            }
          }
          `,
          { email },
        ),
      )) as {
        data: {
          findUserByEmail: UserInterface;
        };
      };

      return findUserByEmail;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }
}

export default UserOperation;
