import { useMemo, useState } from "react";
import { Image, View } from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";
import md5 from "blueimp-md5";

type PropType = {
  email: string;
  size: number;
} & typeof Image.arguments;

export default function ProfileImage({
  email,
  size = 64,
  ...otherProps
}: PropType) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const {
    tokens: { colors },
  } = useLynxTheme();
  const imgElement = useMemo(() => {
    return email ? (
      <Image
        borderRadius={"50%"}
        overflow={"clip"}
        src={`https://www.gravatar.com/avatar/${md5(
          email.trim().toLowerCase(),
        )}?s=${size}`}
        alt="profile"
        onLoad={() => {
          setIsImageLoaded(true);
        }}
        onError={() => {
          setIsImageLoaded(false);
        }}
        {...otherProps}
      />
    ) : null;
  }, [email, otherProps, size]);

  return (
    <>
      {!isImageLoaded && (
        <View
          backgroundColor={colors.gray[10]}
          width={`${size}px`}
          height={`${size}px`}
          borderRadius="50%"
        />
      )}
      {imgElement}
    </>
  );
}
