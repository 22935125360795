import { WebTheme, WebDesignToken } from "@aws-amplify/ui";

const gray10 = "rgb(231, 233, 240)";
const gray20 = "rgb(148, 148, 160)";
const white = "rgb(255, 255, 255)";
const whiteAlpha30 = "rgba(249, 249, 249, .30)";

const brandPrimary = "rgb(12, 33, 56)";
const brandSecondary = "rgb(31, 91, 156)";
const brandTertiary = "rgb(18, 18, 18)";
const brandError = "rgb(183, 28, 28)";
const brandColors = {
  primary: { value: brandPrimary },
  secondary: { value: brandSecondary },
  tertiary: { value: brandTertiary },
  error: { value: brandError },
};

const fontFamily = "'Noto Sans', sans-serif";
const fontWeightBold = 700;

const spaceLarge = "2rem"; // 24px
const spaceMedium = "1.33rem"; // 16px
const spaceSmall = "1.17rem"; // 14px
const spaceXL = "2.67rem"; // 32px
const spaceXXL = "3.33rem"; // 40px
const spaceXXXL = "4rem"; // 48px
const spaceXXXXL = "4.667rem"; // 56px
const spaceXXXXXL = "5.333rem"; // 64px
const spaceXS = "1rem"; // 12px
const spaceXXS = "0.67rem"; // 8px
const spaceXXXS = "0.33rem"; // 4px

type brandColorsType = {
  primary: string;
  secondary: string;
  tertiary: string;
  error: string;
};

interface LynxTheme {
  name: string;
  cssText: string;
  breakpoints: WebDesignToken<string>;
  tokens: {
    colors: {
      brand: brandColorsType;
      font: brandColorsType;
      white: string;
      gray: {
        10: string;
        20: string;
      };
      skill: WebDesignToken<string>;
      clientMatch: WebDesignToken<string>;
      clientToAvoid: WebDesignToken<string>;
      areasOfInterest: WebDesignToken<string>;
      areasToAvoid: WebDesignToken<string>;
      idealProjectLength: WebDesignToken<string>;
    };
    columns: {
      1: WebDesignToken<string>;
      2: WebDesignToken<string>;
      3: WebDesignToken<string>;
      4: WebDesignToken<string>;
      5: WebDesignToken<string>;
      6: WebDesignToken<string>;
      7: WebDesignToken<string>;
      8: WebDesignToken<string>;
      9: WebDesignToken<string>;
      10: WebDesignToken<string>;
      11: WebDesignToken<string>;
      12: WebDesignToken<string>;
    };
    zIndex: {
      foreground: string;
      background: string;
      overlay: string;
      loadingScreen: string;
    };
    icons: {
      scale: {
        large: WebDesignToken<string>;
        medium: WebDesignToken<string>;
        small: WebDesignToken<string>;
      };
      translate: {
        adjustmentx: WebDesignToken<string>;
        adjustmenty: WebDesignToken<string>;
      };
    };
  };
}

export type LynxWebTheme = LynxTheme & WebTheme;

export const lynxTheme = {
  name: "lynx-theme",
  cssText: "",
  breakpoints: {},
  tokens: {
    colors: {
      brand: brandColors,
      font: brandColors,
      white: {
        value: white,
      },
      gray: {
        10: { value: gray10 },
        20: { value: gray20 },
      },
      skill: {
        value: "rgb(48, 79, 254)",
      },
      clientMatch: {
        value: "rgb(27, 94, 32)",
      },
      clientToAvoid: {
        value: "rgb(170, 0, 255)",
      },
      areasOfInterest: {
        value: "rgb(62, 39, 35)",
      },
      areasToAvoid: {
        value: "rgb(213, 0, 0)",
      },
      idealProjectLength: {
        value: "rgb(1, 87, 155)",
      },
    },
    columns: {
      1: {
        value: "8.333%",
      },
      2: {
        value: "16.666%",
      },
      3: {
        value: "25%",
      },
      4: {
        value: "33.333%",
      },
      5: {
        value: "41.666%",
      },
      6: {
        value: "50%",
      },
      7: {
        value: "58.333%",
      },
      8: {
        value: "66.666%",
      },
      9: {
        value: "75%",
      },
      10: {
        value: "83.333%",
      },
      11: {
        value: "91.666%",
      },
      12: {
        value: "100%",
      },
    },
    components: {
      heading: {
        1: {
          fontSize: { value: "{fontSizes.xxl}" },
          fontWeight: { value: fontWeightBold },
        },
        2: {
          fontSize: { value: "{fontSizes.xl}" },
          fontWeight: { value: fontWeightBold },
        },
        3: {
          fontSize: { value: "{fontSizes.large}" },
          fontWeight: { value: fontWeightBold },
        },
        4: {
          fontSize: { value: "{fontSizes.medium}" },
          fontWeight: { value: fontWeightBold },
        },
        5: {
          fontSize: { value: "{fontSizes.small}" },
          fontWeight: { value: fontWeightBold },
        },
        6: {
          fontSize: { value: "{fontSizes.xs}" },
          fontWeight: { value: fontWeightBold },
        },
      },
      badge: {
        backgroundColor: { value: white },
      },
      button: {
        borderWidth: {
          value: 0,
        },
        _hover: {
          backgroundColor: { value: whiteAlpha30 },
        },
        _active: {
          backgroundColor: { value: whiteAlpha30 },
        },
      },
      card: {
        borderRadius: {
          value: spaceXXXS,
        },
        padding: {
          value: spaceMedium,
        },
      },
      field: {
        fontSize: {
          value: "{fontSizes.small}",
        },
        gap: {
          value: spaceXXXS,
        },
        label: {
          color: { value: brandTertiary },
          fontSize: { value: "{fontSizes.small}" },
        },
      },
      fieldcontrol: {
        borderColor: { value: gray20 },
        borderWidth: { value: "1px" },
        borderRadius: { value: spaceXXXS },
        focusBorderColor: { value: brandPrimary },
        focusBoxShadow: { value: "none" },
        transitionDuration: { value: 0 },
        paddingBlockStart: { value: spaceXXS },
        paddingBlockEnd: { value: spaceXXS },
        paddingInlineEnd: { value: spaceXXS },
        paddingInlineStart: { value: spaceXXS },
      },
      table: {
        header: {
          padding: {
            value: `${spaceXXXS} ${spaceXS}`,
          },
          fontSize: {
            value: "{fontSizes.small}",
          },
        },
        data: {
          padding: {
            value: spaceXS,
          },
        },
      },
      radio: {
        button: {
          borderWidth: {
            value: "1px",
          },
          padding: {
            value: "0",
          },
          _checked: {
            color: {
              value: brandSecondary,
            },
          },
        },
      },
      fieldmessages: {
        description: {
          color: { value: gray20 },
        },
      },
      checkbox: {
        icon: {
          background: {
            color: { value: brandSecondary },
          },
        },
      },
      link: {
        color: { value: brandSecondary },
        textDecoration: { value: "underline" },
        visited: {
          color: { value: brandSecondary },
        },
      },
    },
    space: {
      large: { value: spaceLarge }, // 24px
      medium: { value: spaceMedium }, // 16px
      small: { value: spaceSmall }, // 14px
      xl: { value: spaceXL }, // 32px
      xxl: { value: spaceXXL }, // 40px
      xxxl: { value: spaceXXXL }, // 48px
      xxxxl: { value: spaceXXXXL }, // 56px
      xxxxxl: { value: spaceXXXXXL }, // 64px
      xs: { value: spaceXS }, // 12px
      xxs: { value: spaceXXS }, // 8px
      xxxs: { value: spaceXXXS }, // 4px
    },
    fonts: {
      default: {
        static: { value: fontFamily },
        variable: { value: fontFamily },
      },
    },
    zIndex: {
      foreground: {
        value: 1,
      },
      background: {
        value: -1,
      },
      overlay: {
        value: 2,
      },
      loadingScreen: {
        value: 3,
      },
    },
    icons: {
      scale: {
        large: {
          value: 1.5,
        },
        medium: {
          value: 1.25,
        },
        small: {
          value: 1.05,
        },
      },
      translate: {
        adjustmentx: {
          value: "5%",
        },
        adjustmenty: {
          value: "-5%",
        },
      },
    },
  },
};
