import { ICON_NAMES, IconDefinitions } from "../../../util/attributesIconsUtil";
import { sanitizeString } from "../../../util/stringOperation";

export type PropTypes = {
  label: string;
  size?: "small" | "medium" | "large";
};

const defaultProps = {
  label: "",
  size: "small",
};

const getIcon = (label: string) => {
  const sanitizedLabel: string = sanitizeString(label) ?? "";
  if (sanitizedLabel === "")
    throw new Error(
      `ERROR: 'label' property is invalid, it cannot be an empty string.`,
    );

  return (ICON_NAMES as { [key: string]: IconDefinitions })[sanitizedLabel];
};

export default function LynxIcon({ label, size }: PropTypes) {
  const IconElement: IconDefinitions = getIcon(label);

  return IconElement ? (
    <IconElement
      aria-label={label}
      style={{
        transform: `scale(var(--amplify-icons-scale-${size}))`,
      }}
    />
  ) : null;
}

LynxIcon.defaultProps = defaultProps;
