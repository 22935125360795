import { TextField } from "@aws-amplify/ui-react";
import "./style.scss";

type PropTypes = typeof TextField.arguments;

export default function LyunxInput({ children, ...otherProps }: PropTypes) {
  return (
    <TextField className="lynx-input" {...otherProps}>
      {children}
    </TextField>
  );
}
