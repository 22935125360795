import {
  FaAws,
  FaReact,
  FaAngular,
  FaSquare,
  FaWordpress,
  FaYoutube,
  FaRobot,
  FaFacebook,
  FaTimes,
  FaArrowLeft,
  FaEdit,
  FaGoogle,
  FaArrowRight
} from "react-icons/fa";
import type { IconType } from "react-icons";

export type IconDefinitions = IconType;

export const ICON_NAMES = {
  react: FaReact,
  reactjs: FaReact,
  angular: FaAngular,
  fbpalette: FaFacebook,
  fbgalaxy: FaFacebook,
  square: FaSquare,
  wordpress: FaWordpress,
  aws: FaAws,
  youtube: FaYoutube,
  machinelearning: FaRobot,
  close: FaTimes,
  leftarrow: FaArrowLeft,
  rightarrow: FaArrowRight,
  edit: FaEdit,
  googlecloud: FaGoogle
};
