import { Radio } from "@aws-amplify/ui-react";
import "./style.scss";

/**
 * @component LynxRadio
 * @description
 * A branded wrapper radio component for the Lynx design system
 * @param {String} [className]
 * @param {{'large', 'medium', 'small'}} [size]
 * @returns
 */

type PropTypes = {
  className: string;
  value: string;
  size: "large" | "medium" | "small";
  children: React.ReactNode;
} & typeof Radio.arguments;

export default function LynxRadio({
  size = "medium",
  className = "",
  value,
  children,
  ...otherProps
}: PropTypes) {
  return (
    <Radio
      className={`lynx-radio ${size} ${className}`}
      value={value}
      {...otherProps}
    >
      {children}
    </Radio>
  );
}
