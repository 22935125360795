import React from "react";
import { Flex, View } from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";

export enum TEMPLATE_TYPES {
  FULL_WIDTH,
  DASHBOARD,
}

type PropType = {
  children: React.ReactNode;
  variants: TEMPLATE_TYPES;
} & typeof View.arguments;

export default function Templates({
  variants,
  children,
  ...otherProps
}: PropType) {
  const {
    tokens: { columns },
  } = useLynxTheme();

  switch (variants) {
    case TEMPLATE_TYPES.DASHBOARD:
      return (
        <View width={columns[12].value} {...otherProps}>
          {children}
        </View>
      );
    case TEMPLATE_TYPES.FULL_WIDTH:
      return (
        <Flex width={columns[12].value} {...otherProps}>
          <View width={columns[10].value} margin="0 auto">
            {children}
          </View>
        </Flex>
      );
    default:
      return <View {...otherProps}>{children}</View>;
  }
}
