import { LocationsEnum, UserTypesEnum } from "../interface";
import { UserRoleEnum, UserLevelEnum } from "../../src/models/index";

export const UserLevelsName = {
  [UserLevelEnum.DIRECTOR]: "Director",
  [UserLevelEnum.INTERMEDIATE]: "Intermediate",
  [UserLevelEnum.JUNIOR]: "Junior",
  [UserLevelEnum.SENIOR]: "Senior",
};

export const UserRolesName = {
  [UserRoleEnum.ASSOCIATE_TECHNICAL_ARCHITECT]: "Associate Technical Architect",
  [UserRoleEnum.ASSOCIATE_TECHNICAL_DIRECTOR]: "Associate Technical Director",
  [UserRoleEnum.BACK_END_DEVELOPER]: "Back End Developer",
  [UserRoleEnum.CREATIVE_DEVELOPER]: "Creative Developer",
  [UserRoleEnum.DEV_OPS_DEVELOPER]: "Dev Ops Developer",
  [UserRoleEnum.FRONT_END_DEVELOPER]: "Front End Developer",
  [UserRoleEnum.FULL_STACK_DEVELOPER]: "Full Stack Developer",
  [UserRoleEnum.TECHNICAL_ARCHITECT]: "Technical Architect",
  [UserRoleEnum.TECHNICAL_DIRECTOR]: "Technical Director",
};

export const LocationsName = {
  [LocationsEnum.AMSTERDAM]: "Amsterdam",
  [LocationsEnum.MONTEVIDEO]: "Montevideo",
  [LocationsEnum.LOS_ANGELES]: "Los Angeles",
  [LocationsEnum.TORONTO]: "Toronto",
  [LocationsEnum.NEW_YORK]: "New York",
};

export const UserTypesName = {
  [UserTypesEnum.DEV]: "Dev",
  [UserTypesEnum.RESOURCE]: "Resource",
};
