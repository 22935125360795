import { useEffect, useRef, MutableRefObject } from "react";
import p5 from "p5";

import { View } from "@aws-amplify/ui-react";

type PropType = {
  background: string;
  width: string;
  scale: number;
};

export default function SpinningLogo({
  background,
  width,
  scale = 1,
}: PropType) {
  const canvasInstanceRef = useRef<p5>();
  const canvasParentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const size = 6;
    const TWO_PI = Math.PI * 2;
    const TRIANGLE_STRIP = 5;

    function strokeLeft(p5: p5) {
      p5.push();

      for (let u = 0; u < TWO_PI; u += 0.1) {
        p5.beginShape(TRIANGLE_STRIP);

        for (let v = -Math.PI; v < Math.PI; v += 0.1) {
          let x = Math.cos(u) * (1 + 2 * Math.cos(v));
          let y = Math.sin(u) * (1 + 2 * Math.cos(v));
          const z = 12 * Math.sin(v);
          p5.vertex(size * x, size * y, size * z);

          x = Math.cos(u + 0.1) * (1 + 2 * Math.cos(v));
          y = Math.sin(u + 0.1) * (1 + 2 * Math.cos(v));
          p5.vertex(size * x, size * y, size * z);
        }

        p5.endShape("close");
      }
      p5.pop();
    }

    function strokeRight(p5: p5) {
      p5.push();
      p5.translate(40, 60, 0);
      p5.rotateX(1);
      p5.rotateY(-0.5);
      for (let u = 0; u < TWO_PI; u += 0.1) {
        p5.beginShape(TRIANGLE_STRIP);
        for (let v = -Math.PI; v < Math.PI; v += 0.1) {
          let x = Math.cos(u) * (1 + 2 * Math.cos(v));
          let y = Math.sin(u) * (1 + 2 * Math.cos(v));
          const z = 7 * Math.sin(v);
          p5.vertex(size * x, size * y, size * z);

          x = Math.cos(u + 0.1) * (1 + 2 * Math.cos(v));
          y = Math.sin(u + 0.1) * (1 + 2 * Math.cos(v));
          p5.vertex(size * x, size * y, size * z);
        }

        p5.endShape("close");
      }
      p5.pop();
    }

    function drawShape(p5: p5) {
      p5.push();
      p5.rotateY(p5.frameCount * 0.01);
      p5.push();
      p5.translate(-20, 0, 0);
      p5.rotateX(1.5);
      p5.rotateY(6.7);
      p5.rotateZ(-0.8);

      p5.fill(255);
      strokeRight(p5);
      strokeLeft(p5);

      p5.pop();
      p5.pop();
    }

    function removeCanvasInstance(
      canvasInstanceRef: MutableRefObject<p5 | undefined>,
    ) {
      canvasInstanceRef.current?.remove();
      canvasInstanceRef.current = undefined;
    }

    const setup = (p5: p5, canvasParentRef: Element) => {
      p5.setup = () => {
        p5.createCanvas(
          (window.innerWidth * parseInt(width)) / 100,
          window.innerHeight,
          "webgl",
        ).parent(canvasParentRef);
        p5.noStroke();
      };

      p5.draw = () => {
        p5.background("black");
        p5.orbitControl(3, 3, 0.01);
        p5.ambientLight(170);
        p5.spotLight(255, 255, 255, 0, 0, 500, 0, 0, -1, Math.PI / 2);
        p5.noStroke();
        p5.scale(scale);
        drawShape(p5);
      };
    };

    if (canvasParentRef.current instanceof HTMLElement) {
      removeCanvasInstance(canvasInstanceRef);
      const p5Instance = new p5(setup, canvasParentRef.current);
      canvasInstanceRef.current = p5Instance;
      return () => removeCanvasInstance(canvasInstanceRef);
    }
  }, [background, canvasParentRef, scale, width]);

  return <View ref={canvasParentRef} />;
}
