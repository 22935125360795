import React from "react";
import { useLynxTheme } from "../../../hooks";
import LynxButton, { LynxButtonThemeEnum } from "../LynxButton";

/**
 * @component LynxIconButton
 * @description
 * A branded wrapper icon-button component for the Lynx design system
 * @param {{'primary', 'secondary'}} [theme]
 * @param { React.ReactNode } [icon]
 * @param {{'large', 'medium', 'small'}} [size]
 * @returns
 */

type PropTypes = {
  theme: LynxButtonThemeEnum;
  size: "large" | "medium" | "small";
  icon: React.ReactNode;
} & typeof LynxButton.arguments;

export default function LynxIconButton({
  theme,
  size,
  icon,
  ...otherProps
}: PropTypes) {
  const {
    tokens: { space },
  } = useLynxTheme();

  function getSize() {
    switch (size) {
      case "large":
        return {
          width: space.xxl,
          height: space.xxl,
        };
      case "medium":
        return {
          width: space.xl,
          height: space.xl,
        };
      case "small":
        return {
          width: space.large,
          height: space.large,
        };
    }
  }

  return (
    <LynxButton
      {...getSize()}
      theme={theme ?? "primary"}
      borderRadius="50%"
      className={"lynx-icon-button"}
      padding={"0"}
      {...otherProps}
    >
      {icon}
    </LynxButton>
  );
}
