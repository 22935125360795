import { useState, useEffect, useCallback } from "react";
import { Loader } from "@aws-amplify/ui-react";
import ProfileImage from "./ProfileImage";
import ModalHeader from "./ModalHeader";
import UserProfileForm from "./UserProfileForm";
import {
  Badge,
  Button,
  Grid,
  Flex,
  View,
  Heading,
  Text,
} from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";
import UserOperation from "../api/UserOperation";
import {
  groupSkillsByType,
  getProfileColorByType,
  PROFILE_TYPES_NAME,
} from "../util/skillsDataUtil";
import { UserLevelsName, LocationsName } from "../util/userDataUtil";
import { LocationTimeZone } from "../util/timezoneUtil";
import {
  ProfileTypesEnum,
  LocationsEnum,
  UserLevelsEnum,
  UserInterface,
} from "../interface";
import { LynxParagraph } from "./atoms";
import LynxIcon from "./atoms/LynxIcon/";

type PropTypes = {
  OAuthProfileEmail: string;
  targetedUserId: string;
};

export default function UserProfile({
  OAuthProfileEmail,
  targetedUserId,
}: PropTypes) {
  const {
    tokens: { colors, space, fontSizes },
  } = useLynxTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [isProfileEditable, setIsProfileEditable] = useState(false);
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [userProfile, setUserProfile] = useState<UserInterface | null>(null);
  const fetchUserProfile = useCallback(async () => {
    const userProfile = await new UserOperation().getUser(targetedUserId);
    setUserProfile(userProfile);
    setIsLoading(false);
    return userProfile;
  }, [targetedUserId]);
  const getIsProfileEditable = useCallback(
    async (userEmail: string, managerId?: string | undefined) => {
      if (managerId) {
        const { email: managerEmail } = await new UserOperation().getUser(
          managerId,
        );
        setIsProfileEditable(
          managerEmail === OAuthProfileEmail || userEmail === OAuthProfileEmail,
        );
      } else {
        setIsProfileEditable(userEmail === OAuthProfileEmail);
      }
    },
    [],
  );

  useEffect(() => {
    (async () => {
      if (!targetedUserId) {
        return;
      }
      try {
        const userProfile = await fetchUserProfile();
        getIsProfileEditable(userProfile.email, userProfile.manager);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [OAuthProfileEmail, fetchUserProfile, targetedUserId]);

  function renderUserAttributes() {
    const groupeduserProfileSkills = userProfile?.skills
      ? groupSkillsByType(userProfile.skills.items.map(({ skill }) => skill))
      : null;
    return groupeduserProfileSkills
      ? Object.keys(groupeduserProfileSkills).map((objKey, key) => (
          <Flex key={key} gap={space.xxs} direction={"column"}>
            <Flex gap={space.xxxs} alignItems="center">
              <View
                width={space.xxs}
                height={space.xxs}
                borderRadius="50%"
                backgroundColor={`${getProfileColorByType(
                  objKey as ProfileTypesEnum,
                )}`}
              />
              <Heading level={5}>
                {PROFILE_TYPES_NAME[objKey as keyof typeof ProfileTypesEnum]}
              </Heading>
            </Flex>
            <Flex gap={space.xxs} padding={"0"} wrap="wrap">
              {groupeduserProfileSkills[objKey]?.map(
                (skill, skillKey) =>
                  !skill.removedFromUser && (
                    <Badge
                      backgroundColor={colors.gray[10]}
                      size="small"
                      key={skillKey}
                      style={{ gap: space.xxs.value }}
                    >
                      <LynxIcon label={skill.name} />
                      <Text as="p">{skill.name}</Text>
                    </Badge>
                  ),
              )}
            </Flex>
          </Flex>
        ))
      : null;
  }

  if (isLoading) {
    return <Loader ariaLabel={"Fetching User Profile"} />;
  }

  return userProfile ? (
    <>
      <ModalHeader>
        {isProfileEditable ? (
          <Button
            gap={space.xxxs}
            padding={"0"}
            size="large"
            onClick={() => setIsEditingProfile((state) => !state)}
          >
            <LynxIcon label="leftArrow" />
            <Heading>
              {isEditingProfile ? "Preview Profile" : "Edit profile"}
            </Heading>
          </Button>
        ) : (
          <Heading>Profile</Heading>
        )}
      </ModalHeader>
      {isEditingProfile ? (
        <UserProfileForm
          formData={userProfile}
          updateFormData={() => {
            setUserProfile(null);
            setIsLoading(true);
            fetchUserProfile();
          }}
        />
      ) : (
        <Grid gap={space.large}>
          <Grid templateColumns="0.7fr 1.7fr" gap={space.medium}>
            <View>
              <Flex
                alignItems="center"
                margin={`0 0 ${space.medium} 0`}
                wrap="wrap"
              >
                <ProfileImage email={userProfile.email} />
                <View>
                  <Heading level={4}>
                    {userProfile.firstName} {userProfile.lastName}
                  </Heading>
                  <Text fontSize={fontSizes.small}>{userProfile.email}</Text>
                </View>
              </Flex>
              <Flex
                direction="column"
                gap={space.medium}
                backgroundColor={colors.gray[10]}
                padding={space.medium}
                borderRadius={space.xxs}
              >
                <View
                  as="ul"
                  style={{
                    margin: 0,
                    padding: 0,
                    listStyleType: "none",
                  }}
                >
                  {[
                    { label: "Title", data: userProfile.title },
                    {
                      label: "Level",
                      data: userProfile.level
                        ? UserLevelsName[
                            userProfile.level as keyof typeof UserLevelsEnum
                          ]
                        : null,
                    },
                    { label: "User Type", data: userProfile.type },
                    {
                      label: "Location",
                      data: userProfile.location
                        ? LocationsName[
                            userProfile.location as keyof typeof LocationsEnum
                          ]
                        : null,
                    },
                    {
                      label: "Timezone",
                      data: userProfile.location
                        ? new Date(
                            new Date().toLocaleString("en-US", {
                              timeZone:
                                LocationTimeZone[
                                  userProfile.location as LocationsEnum
                                ],
                            }),
                          ).toLocaleTimeString()
                        : null,
                    },
                  ].map(({ label, data }, key) => (
                    <View key={key} as="li" margin={`0 0 ${space.medium} 0`}>
                      <Heading level={5}>{label}</Heading>
                      {data ? <LynxParagraph>{data}</LynxParagraph> : "--"}
                    </View>
                  ))}
                </View>
              </Flex>
            </View>
            <Flex direction="column" gap={space.large}>
              <View>
                <Heading level={5}>About</Heading>
                <LynxParagraph>{userProfile.desc}</LynxParagraph>
              </View>
              {renderUserAttributes()}
            </Flex>
          </Grid>
        </Grid>
      )}
    </>
  ) : (
    <Loader ariaLabel={"Fetching User Profile"} />
  );
}
