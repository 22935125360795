import { AuthorizationError } from "../interface";
import { Logger } from "aws-amplify";

export const AUTHORIZATION_MESSAGE = {
  [AuthorizationError.GeneralError]:
    "User failed authorization and authentication",
  [AuthorizationError.PreSignUpError]: "User not authorized",
  [AuthorizationError.PreAuthenticateError]:
    "User not authorized to authenticate",
};

export function handleOAuthError(
  urlParams: URLSearchParams,
): AuthorizationError | null {
  const logger = new Logger("AuthorizationError");
  const error = urlParams.get("error");
  const errorDescription = urlParams.get("error_description");

  if (!errorDescription && !error) {
    return null;
  }

  if (!errorDescription) {
    return error ? AuthorizationError.GeneralError : null;
  }

  if (errorDescription?.startsWith("PreSignUp")) {
    logger.error(AUTHORIZATION_MESSAGE[AuthorizationError.PreSignUpError]);
    return AuthorizationError.PreSignUpError;
  }

  if (errorDescription?.startsWith("PreAuthentication")) {
    logger.error(
      AUTHORIZATION_MESSAGE[AuthorizationError.PreAuthenticateError],
    );
    return AuthorizationError.PreAuthenticateError;
  }

  return null;
}
