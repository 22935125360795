import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useURLQuery, useLynxTheme } from "../hooks";
import UserOperation from "../api/UserOperation";
import { sanitizeString } from "../util/stringOperation";
import {
  getProfileColorByType,
  groupSkillsByType,
  hasExactAttributeMatch,
} from "../util/skillsDataUtil";
import styled from "styled-components";
import {
  Badge,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Flex,
  Text,
} from "@aws-amplify/ui-react";
import {
  ProfileTypesEnum,
  SkillInterface,
  UserInterface,
  SearchFilterInterface,
  UserTypesEnum,
  UserLevelsEnum,
  LocationsEnum,
} from "../interface";
import {
  UserLevelsName,
  UserRolesName,
  LocationsName,
} from "../util/userDataUtil";
import { LynxTooltip } from "./atoms";
import Fuse from "fuse.js";
import LynxIcon from "./atoms/LynxIcon/";

const ProfileTypesObj = {
  [ProfileTypesEnum.SKILL]: "Skills",
  [ProfileTypesEnum.IDEAL_PROJECT_LENGTH]: "Ideal Project Length",
  [ProfileTypesEnum.CLIENTS_MATCH]: "Clients Match",
  [ProfileTypesEnum.AREAS_OF_INTEREST]: "Areas of Interest",
  [ProfileTypesEnum.CLIENTS_TO_AVOID]: "Clients to avoid",
  [ProfileTypesEnum.AREAS_TO_AVOID]: "Areas to avoid",
};

const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  position: relative;
  &:after,
  td:first-child:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    background: var(--amplify-colors-gray-10);
    width: 100%;
    height: 100%;
    transform: scaleY(0);
    transition: all var(--animation-speed-fast) var(--animation-timing-1);
    transform-origin: bottom;
    z-index: var(--amplify-z-index-background);
  }
  td:first-child {
    background: var(--amplify-colors-white);
  }
  td {
    border: 0.5px var(--amplify-components-table-data-border-style)
      var(--amplify-components-table-data-border-color);
  }
  &:hover {
    &:after,
    td:first-child:before {
      transform: scaleY(1);
    }
  }
` as typeof TableRow;

const StyledTopTableRow = styled(TableRow)`
  z-index: var(--amplify-z-index-overlay);
  position: sticky;
  top: 0;
  th {
    background-color: var(--amplify-colors-white);
    border: 0.5px var(--amplify-components-table-data-border-style)
      var(--amplify-components-table-data-border-color);
  }
` as typeof TableRow;

type PropType = {
  searchFilter: SearchFilterInterface;
};

export default function UsersTable({ searchFilter }: PropType) {
  const [usersData, setUsersData] = useState<Array<UserInterface>>([]);
  const { searchField, skillsFilter } = searchFilter;
  const [fuseCollection, setFuseCollection] = useState<
    Fuse<UserInterface> | undefined
  >();
  const {
    tokens: { space, colors, columns, zIndex },
  } = useLynxTheme();
  const navigate = useNavigate();
  const urlQuery = useURLQuery();

  useEffect(() => {
    const userOperation = new UserOperation();
    (async () => {
      const fetchedUsersData = await userOperation.getUsers();
      setUsersData(fetchedUsersData);
      setFuseCollection(
        new Fuse(fetchedUsersData, {
          includeScore: true,
          shouldSort: true,
          includeMatches: true,
          keys: ["firstName", "lastName"],
        }),
      );
    })();
  }, []);

  function _isUserMatchSkillsFilter({
    role,
    level,
    skills,
  }: {
    role: string;
    level: string;
    skills: Array<{ skill: SkillInterface }>;
  }) {
    return (
      skillsFilter.length <= 0 ||
      skillsFilter.every(({ name: filterName, types: filterType }) => {
        return (
          (filterType === "Level" &&
            filterName ===
              UserLevelsName[level as keyof typeof UserLevelsName]) ||
          (filterType === "Role" &&
            filterName === UserRolesName[role as keyof typeof UserRolesName]) ||
          skills.find(
            ({ skill: { name, types } }) =>
              filterName === name && filterType === types,
          ) !== undefined
        );
      })
    );
  }

  function _highlightMatchingTerm(
    match: Fuse.FuseResultMatch,
    defaultValue = "",
  ) {
    const { key, value, indices } = match;
    if (!key || !value || indices.length === 0) {
      return defaultValue;
    }
    const stringIndices = indices.flat();
    const valueArr = value.split("");
    let start: number | null = null;
    let termLength: number | null = null;
    let count = 0;

    return (
      <>
        {valueArr.map((char, index) => {
          if (termLength && index <= termLength) {
            return null;
          }
          termLength = null;
          if (index === stringIndices[count]) {
            start = stringIndices[count];
            termLength = stringIndices[count + 1];
            count += 2;
            return (
              <b style={{ backgroundColor: colors.gray[10] }}>
                {value.substring(start, termLength + 1)}
              </b>
            );
          }
          return char;
        })}
      </>
    );
  }

  function AttributesColumns({
    userAttributes,
    userAttributeKey,
  }: {
    userAttributes: Array<SkillInterface>;
    userAttributeKey: ProfileTypesEnum;
  }) {
    if (
      skillsFilter &&
      skillsFilter.filter(({ types }) => types !== "Level" && types !== "Role")
        .length > 0
    ) {
      const matchingAttributes = userAttributes
        .map((skill, attrKey) => {
          if (hasExactAttributeMatch(skill, skillsFilter)) {
            return (
              <Badge
                color={colors.white}
                backgroundColor={getProfileColorByType(skill.types)}
                key={attrKey}
                size="small"
                style={{ gap: space.xxs.value }}
              >
                <LynxIcon label={skill.name} />
                <Text as="p" color={colors.white}>
                  {skill.name}
                </Text>
              </Badge>
            );
          }
          return null;
        })
        .filter((attr) => attr);
      return matchingAttributes.length > 0 ? (
        <Flex alignItems="center" wrap="wrap" gap={space.xxxs}>
          {matchingAttributes}
        </Flex>
      ) : null;
    }

    let userAttributesCluster: undefined | Array<SkillInterface>;
    return (
      <Flex alignItems="center" wrap="wrap" gap={space.xxxs}>
        {userAttributes.map((userAttribute, key) => {
          if (key >= 3) {
            if (userAttributesCluster) {
              return null;
            }
            userAttributesCluster = userAttributes.slice(3);
            return (
              <LynxTooltip
                title={userAttributesCluster
                  .map((skill) => skill.name)
                  .join(",")}
                boxShadow={`inset ${getProfileColorByType(
                  userAttributeKey,
                )} 0 0 0 3px`}
                padding={space.xs}
                margin={`${space.xxxs} 0 0`}
                key={key}
              >
                <Badge
                  size="small"
                  boxShadow={`inset ${getProfileColorByType(
                    userAttributeKey,
                  )} 0 0 0 3px`}
                >
                  {`+ ${userAttributesCluster.length}`}
                </Badge>
              </LynxTooltip>
            );
          }
          const { name, types } = userAttribute;
          return (
            <Badge
              color={colors.white}
              backgroundColor={getProfileColorByType(types)}
              key={name + types + key}
              size="small"
              style={{ gap: space.xxs.value }}
            >
              <LynxIcon label={name} />
              <Text as="p" color={colors.white}>
                {name}
              </Text>
            </Badge>
          );
        })}
      </Flex>
    );
  }

  const _handleRowClick = (userId: string) => {
    urlQuery.set("id", userId);
    navigate({
      search: urlQuery.toString(),
    });
  };

  const getUsersData = useMemo(() => {
    const sanitizedSearchField = sanitizeString(searchField);
    if (
      fuseCollection &&
      typeof sanitizedSearchField === "string" &&
      sanitizedSearchField.length > 0
    ) {
      const searchResult = fuseCollection.search(sanitizedSearchField);
      return searchResult.length === 0 ? [] : searchResult;
    }
    return usersData
      .map((item) => {
        return {
          item,
          matches: null,
        };
      })
      .sort((a, b) => a.item.firstName.localeCompare(b.item.firstName));
  }, [fuseCollection, searchField, usersData]);

  const stickyColumnStyle = {
    position: "sticky",
    left: 0,
    borderRight: `1px solid ${colors.gray[10]}`,
    whiteSpace: "nowrap",
    maxWidth: "256px",
    minWidth: "fit-content",
    width: columns[1].value,
    zIndex: zIndex.foreground,
  } as React.CSSProperties;

  return (
    <Table
      style={{
        borderCollapse: "separate",
        width: "max-content",
        minWidth: "100%",
        maxWidth: "145%",
      }}
    >
      <TableHead>
        <StyledTopTableRow>
          <TableCell
            as="th"
            backgroundColor={colors.white}
            style={stickyColumnStyle}
          >
            Name
          </TableCell>
          <TableCell as="th">Ex. Level</TableCell>
          <TableCell as="th">Office</TableCell>
          {Object.entries(ProfileTypesObj).map((entry, key) => {
            const [type, name] = entry;
            const thElement = (
              <TableCell as="th" key={key}>
                {name}
              </TableCell>
            );
            if (skillsFilter.length > 0) {
              return skillsFilter.some(
                ({ types }) =>
                  types === "Level" || types === "Role" || types === type,
              )
                ? thElement
                : null;
            } else {
              return thElement;
            }
          })}
        </StyledTopTableRow>
      </TableHead>
      <TableBody>
        {getUsersData &&
          getUsersData.length > 0 &&
          getUsersData.map(
            (
              {
                item: {
                  id,
                  firstName,
                  lastName,
                  role,
                  level,
                  location,
                  type: userType,
                  skills,
                  _deleted,
                },
                matches,
              },
              key,
            ) => {
              const groupedAttributes = groupSkillsByType(
                skills.items.map(({ skill }) => skill),
              );
              const firstNameMatch = matches?.find(
                (match) => match.key === "firstName",
              );
              const lastNameMatch = matches?.find(
                (match) => match.key === "lastName",
              );
              const userTableRow =
                !_deleted && userType !== UserTypesEnum.RESOURCE ? (
                  <StyledTableRow key={key} onClick={() => _handleRowClick(id)}>
                    {firstNameMatch || lastNameMatch ? (
                      <TableCell style={stickyColumnStyle}>
                        {firstNameMatch
                          ? _highlightMatchingTerm(firstNameMatch, firstName)
                          : firstName}{" "}
                        {lastNameMatch
                          ? _highlightMatchingTerm(lastNameMatch, lastName)
                          : lastName}
                      </TableCell>
                    ) : (
                      <TableCell style={stickyColumnStyle}>
                        {firstName} {lastName}
                      </TableCell>
                    )}
                    <TableCell width={columns[1].value}>
                      {UserLevelsName[level as keyof typeof UserLevelsEnum]}
                    </TableCell>
                    <TableCell width={columns[1].value}>
                      {LocationsName[location as keyof typeof LocationsEnum]}
                    </TableCell>
                    {Object.keys(ProfileTypesObj).map((attrKey, key) => {
                      const tableCellElment = !groupedAttributes[attrKey] ? (
                        <TableCell key={key} />
                      ) : (
                        <TableCell key={key}>
                          <AttributesColumns
                            userAttributes={groupedAttributes[attrKey]}
                            userAttributeKey={attrKey as ProfileTypesEnum}
                          />
                        </TableCell>
                      );
                      if (skillsFilter.length > 0) {
                        return skillsFilter.some(
                          ({ types }) =>
                            types === "Level" ||
                            types === "Role" ||
                            types === attrKey,
                        )
                          ? tableCellElment
                          : null;
                      } else {
                        return tableCellElment;
                      }
                    })}
                  </StyledTableRow>
                ) : null;

              return _isUserMatchSkillsFilter({
                role,
                level,
                skills: skills.items,
              })
                ? userTableRow
                : null;
            },
          )}
      </TableBody>
    </Table>
  );
}
