import { UserRoleEnum } from "../models";

export enum AuthorizationError {
  GeneralError,
  PreSignUpError,
  PreAuthenticateError,
}

export enum ProfileTypesEnum {
  SKILL = "SKILL",
  AREAS_OF_INTEREST = "AREAS_OF_INTEREST",
  AREAS_TO_AVOID = "AREAS_TO_AVOID",
  CLIENTS_MATCH = "CLIENTS_MATCH",
  CLIENTS_TO_AVOID = "CLIENTS_TO_AVOID",
  IDEAL_PROJECT_LENGTH = "IDEAL_PROJECT_LENGTH",
}

export enum UserTypesEnum {
  RESOURCE = "RESOURCE",
  DEV = "DEV",
}

export enum UserLevelsEnum {
  JUNIOR = "JUNIOR",
  INTERMEDIATE = "INTERMEDIATE",
  SENIOR = "SENIOR",
  DIRECTOR = "DIRECTOR",
}

export enum LocationsEnum {
  AMSTERDAM = "AMSTERDAM",
  MONTEVIDEO = "MONTEVIDEO",
  LOS_ANGELES = "LOS_ANGELES",
  TORONTO = "TORONTO",
  NEW_YORK = "NEW_YORK",
}

export interface SkillInterface {
  id: string;
  name: string;
  desc: string;
  isNewSkill: boolean;
  removedFromUser: boolean;
  types: ProfileTypesEnum;
  _deleted?: boolean;
}

export interface UserInterface {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  desc: string;
  title: string;
  type: UserTypesEnum;
  level: UserLevelsEnum;
  role: UserRoleEnum;
  location: LocationsEnum;
  skills: { items: Array<{ skill: SkillInterface }> };
  _version: number;
  _deleted: boolean;
  manager?: string;
}

export interface UserSearchableFieldsInterface {
  firstName: string;
  lastName: string;
  email: string;
  desc: string;
}

export interface SkillsFilterOptions {
  name: string;
  types: ProfileTypesEnum | "Role" | "Level";
}

export interface SearchFilterInterface {
  searchField: string;
  skillsFilter: Array<SkillsFilterOptions>;
}

export interface ErrorInterface {
  data: {
    [key: string]: object;
  };
  errors: Array<{
    message: string;
  }>;
}
