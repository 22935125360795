import { useState } from "react";
import { SideBar, UsersTable } from "../components";
import { Grid, View } from "@aws-amplify/ui-react";
import { useLynxTheme } from "../hooks";
import { SearchFilterInterface } from "../interface";

export default function People() {
  const {
    tokens: { columns, space },
  } = useLynxTheme();
  const [userSearch, setUserSearch] = useState<SearchFilterInterface>({
    searchField: "",
    skillsFilter: [],
  });

  return (
    <Grid templateColumns={`${columns[3]} ${columns[9]}`} overflow="hidden">
      <SideBar callBack={setUserSearch} />
      <View
        height={`calc(100vh - var(--nav-height) - ${space.small})`}
        padding={`0 ${space.small} ${space.small} 0`}
        margin={`${space.small} 0 0 ${space.small}`}
        overflow="auto"
      >
        <UsersTable searchFilter={userSearch} />
      </View>
    </Grid>
  );
}
