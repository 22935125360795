import { API, graphqlOperation } from "aws-amplify";
import { ErrorInterface } from "../interface";

class SkillOperation {
  async getSkill(skillId: string) {
    try {
      const {
        data: { listSkills },
      } = (await API.graphql(
        graphqlOperation(
          `
          query GetSkill(id: ID!) {
            getSkill(id: $id) {
              id
              name
            }
          }
          `,
          { id: skillId },
        ),
      )) as {
        data: {
          listSkills: {
            items: [];
          };
        };
      };

      return listSkills.items;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async getSkills() {
    try {
      const {
        data: { listSkills },
      } = (await API.graphql(
        graphqlOperation(
          `
          query GetSkills {
            listSkills {
              items {
                name
                desc
                id
                types
              }
            }
          }
          `,
        ),
      )) as {
        data: {
          listSkills: {
            items: [];
          };
        };
      };

      return listSkills.items;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }

  async createSkills(skillId: Array<string>) {
    if (skillId.length <= 0) {
      return new Error("not data provided");
    }
    try {
      const {
        data: { createSkills },
      } = (await API.graphql(
        graphqlOperation(
          `
          query createSkills(skills: [String!]!) {
            createSkills(input: $skills) {
              items {
                name
                desc
                id
              }
            }
          }
          `,
        ),
      )) as {
        data: {
          createSkills: object;
        };
      };

      return createSkills;
    } catch (error) {
      throw new Error((error as ErrorInterface).errors[0].message);
    }
  }
}

export default SkillOperation;
