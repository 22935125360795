import { HubCapsule } from "@aws-amplify/core";
import { Hub } from "aws-amplify";
export const EventsName = {
  START_REFRESH: "start_refresh",
  REFRESH_USER_CONTENT: "refresh_user_context",
  REFRESH_SKILLS_CONTEXT: "refresh_skills_context",
  COMPLETE_REFRESH: "complete_refresh"
};

class DataEvents {
  channel: string;
  hubListenerCancelAction: () => void;

  constructor(eventName: string, next?: (data: HubCapsule) => void) {
    this.channel = eventName;
    this.hubListenerCancelAction = Hub.listen(this.channel, (data) => {
      next && next(data);
    });
  }

  postMessage(message: string) {
    Hub.dispatch(this.channel, {
      event: message,
    });
  }

  close() {
    this.hubListenerCancelAction();
  }
}

export default DataEvents;
