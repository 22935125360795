import React, { useState, useMemo } from "react";
import { Card, View } from "@aws-amplify/ui-react";
import { v4 as uuidv4 } from "uuid";
import "./style.scss";

/**
 * @component Tooltip
 * @description
 * A tooltip component
 * @param {String} title
 * @returns
 */

type PropTypes = {
  children: React.ReactNode;
  title: string;
} & typeof Card.arguments;

export default function LynxTooltip(props: PropTypes) {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipId = useMemo(() => uuidv4(), []);

  function handleMouseIn() {
    setIsVisible(true);
  }

  function handleMouseLeave() {
    setIsVisible(false);
  }

  return (
    <View
      aria-describedby={tooltipId}
      className="lynx-tooltip"
      title={props.title}
      onMouseEnter={handleMouseIn}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}
      <Card
        id={tooltipId}
        role="tooltip"
        aria-hidden={isVisible}
        className={`tooltip-card ${isVisible ? "show" : "hide"}`}
        {...props}
      >
        {props.title}
      </Card>
    </View>
  );
}
