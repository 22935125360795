import React, { useState } from "react";
import { useURLQuery } from "../hooks";
import SkillEditor from "./SkillEditor";
import ProfileImage from "./ProfileImage";
import { Grid, Text, View, Heading, Flex, Link } from "@aws-amplify/ui-react";
import { LynxButton } from "./atoms";
import UserOperation from "../api/UserOperation";
import { useLynxTheme } from "../hooks";
import {
  UserInterface,
  SkillInterface,
  LocationsEnum,
  UserTypesEnum,
  UserLevelsEnum,
  ErrorInterface,
} from "../interface";
import {
  UserLevelsName,
  LocationsName,
  UserTypesName,
} from "../util/userDataUtil";
import { LynxInput, LynxDropdown } from "./atoms";
import { DataEvents, EventsName } from "../events/index";

enum UPDATE_PROFILE_DATA {
  DESC,
  TITLE,
  LEVEL,
  TYPE,
  LOCATION,
}

type userProfileType = {
  title: { data: string; isEdited: boolean };
  desc: { data: string; isEdited: boolean };
  level: { data: UserLevelsEnum; isEdited: boolean };
  type: { data: UserTypesEnum; isEdited: boolean };
  location: { data: LocationsEnum; isEdited: boolean };
  version: number;
};

type PropType = {
  formData: UserInterface;
  updateFormData: () => void;
};

export default function UserProfileForm({
  formData,
  updateFormData,
}: PropType) {
  const reqeustedUserId = useURLQuery().get("id");
  const [isFormProcessing, setIsFormProcessing] = useState(false);
  const [userEditedSkills, setUserEditedSkill] =
    useState<Array<SkillInterface> | null>(null);
  const {
    tokens: { space, columns, colors },
  } = useLynxTheme();
  const [userProfile, setUserProfile] = useState({
    title: { data: formData.title, isEdited: false },
    desc: { data: formData.desc, isEdited: false },
    level: { data: formData.level, isEdited: false },
    type: { data: formData.type, isEdited: false },
    location: { data: formData.location, isEdited: false },
    version: formData._version,
  });
  const initialProfileData = {
    title: formData.title,
    desc: formData.desc,
    level: formData.level,
    type: formData.type,
    location: formData.location,
  };

  function isProfileEdited() {
    const { desc, title, type, level, location } = userProfile;
    return {
      isSkillEdited: userEditedSkills !== null,
      isProfileDataEdited:
        desc.isEdited ||
        title.isEdited ||
        type.isEdited ||
        location.isEdited ||
        level.isEdited,
    };
  }

  function assertProfileData(data: string, dataType: UPDATE_PROFILE_DATA) {
    switch (dataType) {
      case UPDATE_PROFILE_DATA.LOCATION:
        return Object.values(LocationsEnum).includes(data as LocationsEnum);
      case UPDATE_PROFILE_DATA.LEVEL:
        return Object.values(UserLevelsEnum).includes(data as UserLevelsEnum);
      case UPDATE_PROFILE_DATA.TYPE:
        return Object.values(UserTypesEnum).includes(data as UserTypesEnum);
      default:
        return false;
    }
  }

  function updateProfileData(
    data: string,
    dataType: UPDATE_PROFILE_DATA,
  ): userProfileType {
    switch (dataType) {
      case UPDATE_PROFILE_DATA.TITLE:
        return {
          ...userProfile,
          title: {
            data,
            isEdited: data !== initialProfileData.title,
          },
        };
      case UPDATE_PROFILE_DATA.DESC:
        return {
          ...userProfile,
          desc: {
            data,
            isEdited: data !== initialProfileData.desc,
          },
        };
      case UPDATE_PROFILE_DATA.LEVEL:
        assertProfileData(data, dataType) && new Error("");
        return {
          ...userProfile,
          level: {
            data: data as UserLevelsEnum,
            isEdited: data !== initialProfileData.level,
          },
        };
      case UPDATE_PROFILE_DATA.TYPE:
        return {
          ...userProfile,
          type: {
            data: data as UserTypesEnum,
            isEdited: data !== initialProfileData.type,
          },
        };
      case UPDATE_PROFILE_DATA.LOCATION:
        return {
          ...userProfile,
          location: {
            data: data as LocationsEnum,
            isEdited: data !== initialProfileData.location,
          },
        };
      default:
        return userProfile;
    }
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.stopPropagation();
    event.preventDefault();
    setIsFormProcessing(true);
    if (reqeustedUserId === null || reqeustedUserId === undefined) {
      throw new Error("UserProfileForm component requires user id");
    }
    const userOperation = new UserOperation();
    const { isSkillEdited, isProfileDataEdited } = isProfileEdited();

    if (isSkillEdited || isProfileDataEdited) {
      new DataEvents(EventsName.START_REFRESH).postMessage("update new skills");
    }

    if (isSkillEdited && userEditedSkills) {
      if (userEditedSkills.every(({ id }) => id !== undefined && id !== null)) {
        await userOperation.updateUserSkills(reqeustedUserId, userEditedSkills);
      } else {
        await userOperation.updateUserNewSkills(
          reqeustedUserId,
          userEditedSkills,
        );
        new DataEvents(EventsName.REFRESH_SKILLS_CONTEXT).postMessage(
          "update new skills",
        );
      }
    }

    if (isProfileDataEdited) {
      try {
        await userOperation.updateUser(reqeustedUserId, {
          version: userProfile.version,
          title: userProfile.title.data,
          desc: userProfile.desc.data,
          level: userProfile.level.data,
          type: userProfile.type.data,
          location: userProfile.location.data,
        });
      } catch (error) {
        throw new Error((error as ErrorInterface).errors[0].message);
      }
    }

    if (isSkillEdited || isProfileDataEdited) {
      new DataEvents(EventsName.REFRESH_USER_CONTENT).postMessage(
        "update user profiles",
      );
      updateFormData();
      return;
    }

    setIsFormProcessing(false);
  }

  return (
    <Grid
      as="form"
      gap={space.small}
      templateRows={
        "repeat(3, minmax(60px, auto)) minmax(120px, auto) 2fr minmax(60px, 0.3fr)"
      }
      minHeight="calc(100% - 56px)"
      onSubmit={handleSubmit}
    >
      <Grid gap={space.small} templateColumns="1fr 1fr">
        <Flex direction="column" alignItems="flex-start" gap={space.xxs}>
          <Heading level={5}>
            Profile Image{" "}
            <Text display="inline" color={colors.gray[20]} fontWeight="normal">
              (from <Link href="https://www.gravatar.com/">Gravatar</Link>)
            </Text>
          </Heading>
          <ProfileImage email={formData.email} />
        </Flex>
        <Flex direction="column" alignItems="space-between">
          <View>
            <Heading level={5}>
              Name{" "}
              <Text
                display="inline"
                color={colors.gray[20]}
                fontWeight="normal"
              >
                (from Google)
              </Text>
            </Heading>
            <Text display="inline">
              {formData.firstName} {formData.lastName}
            </Text>
          </View>
          <View>
            <Heading level={5}>
              Email{" "}
              <Text
                display="inline"
                color={colors.gray[20]}
                fontWeight="normal"
              >
                (from Google)
              </Text>
            </Heading>
            <Text display="inline">{formData.email}</Text>
          </View>
        </Flex>
      </Grid>

      <Grid gap={space.small} templateColumns="1fr 1fr">
        <LynxInput
          autoComplete="off"
          as="input"
          label="Title"
          value={userProfile.title.data ?? ""}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setUserProfile(
              updateProfileData(
                event.currentTarget.value,
                UPDATE_PROFILE_DATA.TITLE,
              ),
            )
          }
        />
        <LynxDropdown
          label="Level"
          defaultValue={userProfile.level.data ?? "-"}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setUserProfile(
              updateProfileData(
                event.currentTarget.value,
                UPDATE_PROFILE_DATA.LEVEL,
              ),
            )
          }
        >
          {[...Object.keys(UserLevelsName), "-"].map((value, key) => (
            <option key={key} value={value}>
              {UserLevelsName[value as keyof typeof UserLevelsName] ?? "-"}
            </option>
          ))}
        </LynxDropdown>
      </Grid>

      <Grid gap={space.small} templateColumns="1fr 1fr">
        <LynxDropdown
          label="Location"
          defaultValue={userProfile.location.data ?? "-"}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setUserProfile(
              updateProfileData(
                event.currentTarget.value,
                UPDATE_PROFILE_DATA.LOCATION,
              ),
            )
          }
        >
          {[...Object.keys(LocationsName), "-"].map((value, key) => (
            <option key={key} value={value}>
              {LocationsName[value as keyof typeof LocationsName] ?? "-"}
            </option>
          ))}
        </LynxDropdown>
        <LynxDropdown
          label="User Type"
          defaultValue={userProfile.type.data ?? "-"}
          onChange={(event: React.FormEvent<HTMLInputElement>) =>
            setUserProfile(
              updateProfileData(
                event.currentTarget.value,
                UPDATE_PROFILE_DATA.TYPE,
              ),
            )
          }
        >
          {Object.keys(UserTypesName).map((value, key) => (
            <option key={key} value={value}>
              {UserTypesName[value as keyof typeof UserTypesName] ?? "-"}
            </option>
          ))}
        </LynxDropdown>
      </Grid>

      <LynxInput
        autoComplete="off"
        as="textarea"
        label="Description"
        descriptiveText="Limited to 250 characters. This will show under the about section."
        value={userProfile.desc.data ?? ""}
        resize="vertical"
        maxLength={250}
        onChange={(event: React.FormEvent<HTMLInputElement>) =>
          setUserProfile(
            updateProfileData(
              event.currentTarget.value,
              UPDATE_PROFILE_DATA.DESC,
            ),
          )
        }
      />

      <SkillEditor
        skillsData={formData.skills.items.map(({ skill }) => skill)}
        setIsSkillsEdited={setUserEditedSkill}
      />

      <View width={columns[12]} position="sticky" bottom={space.xs}>
        <View
          height={space.small}
          width={columns[12]}
          backgroundColor={colors.white}
        />
        <LynxButton
          theme="primary"
          width={columns[12]}
          isDisabled={
            isFormProcessing ||
            Object.values(isProfileEdited()).every((state) => !state)
          }
          height="100%"
          type="submit"
        >
          {isFormProcessing ? "Saving" : "Update Profile"}
        </LynxButton>
        <View
          height={space.small}
          width={columns[12]}
          backgroundColor={colors.white}
        />
      </View>
    </Grid>
  );
}
