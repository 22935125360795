import { useEffect, useState } from "react";
import { Loader } from "@aws-amplify/ui-react";

/**
 * @component LynxLoader
 * @description
 * A branded wrapper loader component for the Lynx design system
 * @param {String} [className]
 * @param {{'large', 'medium', 'small'}} [size]
 * @param {{'default', 'linear'}} [variation]
 * @returns
 */

type PropTypes = {
  size: "large" | "medium" | "small";
  children: React.ReactNode | null;
  className: string;
  ariaLabel?: string;
  delay?: number;
} & typeof Loader.arguments;

export default function LynxSuspense({
  ariaLabel,
  children,
  className,
  size,
  delay = 200,
}: PropTypes) {
  const [isDelayed, setIsDelayed] = useState(true);
  useEffect(() => {
    if (delay > 0) {
      setIsDelayed(true);
      window.setTimeout(() => {
        setIsDelayed(false);
      }, delay);
    }
  }, [delay]);

  return isDelayed ? (
    <Loader ariaLabel={ariaLabel} className={className} size={size} />
  ) : (
    children
  );
}
